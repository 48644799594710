import Color from "color";
import { css } from "styled-components";
import { SCTheme } from "../styles/sharedStyles";
import { GlassEffect, NeonGlow } from "../styles/effects";
import brick from "./original/bricks-minimized.jpeg";

const primary = new Color("rgba(255,12,141,0.86)");
const secondary = new Color("rgb(178,51,241)");
const timing = "1s";
const buttonOverrides = () => {
  return [
    GlassEffect(primary.toString(), 0.1),
    css`
      border-radius: 100px;
      width: fit-content;
      box-shadow: 0 8px 4px 0 ${primary.alpha(0.01).toString()};
      &:hover {
        ${GlassEffect(primary.toString(), 0.1)}
        backdrop-filter: blur(2px);
        ${NeonGlow(primary.toString(), 1, true)}
      }
    `,
  ];
};
const buttonSecondaryOverrides = () => {
  return [
    GlassEffect(secondary.toString(), 0.1),
    css`
      border-radius: 100px;
      box-shadow: 0 16px 4px 0 ${secondary.alpha(0.07).toString()};
      &:hover {
        ${GlassEffect(secondary.toString(), 0.1)}
        backdrop-filter: blur(2px);
        ${NeonGlow(secondary.toString(), 1, true)}
      }
    `,
  ];
};

const logoOverrides = () => {
  return [
    css`
      color: white;
      span {
        ${NeonGlow(secondary.toString(), 1.5, true)}
      }
      span:first-child {
        ${NeonGlow(primary.toString(), 1.5, true)}
      }
    `,
  ];
};

const navLogoOverrides = () => {
  return [
    css`
      color: white;
      span {
        ${NeonGlow(secondary.toString(), 0.6, true)}
      }
      span:first-child {
        ${NeonGlow(primary.toString(), 0.6, true)}
      }
    `,
  ];
};

const navOverrides = () => {
  return [
    css`
      body {
        background: url(${brick});
        background-attachment: fixed;
        background-size: 200%;
      }
    `,
  ];
};

export const neonOnBrick: SCTheme = {
  color: {
    body_bg: `url("${brick}")`,
    text: "#FFF",
    text_btn: "#FFF",
    primary: primary.toString(),
    secondary: "rgb(255, 146, 67)",
  },
  cabinet: {
    border: "1px solid #444",
  },
  buttonOverrides: buttonOverrides(),
  buttonSecondaryOverrides: buttonSecondaryOverrides(),
  logoOverrides: logoOverrides(),
  navOverrides: navOverrides(),
};

export const neonOnBrickNav: SCTheme = {
  color: {
    body_bg: `url("${brick}")`,
    text: "#FFF",
    text_btn: "#FFF",
    primary: primary.toString(),
    secondary: "rgb(255, 146, 67)",
  },
  cabinet: {
    border: "1px solid #444",
  },
  buttonOverrides: buttonOverrides(),
  buttonSecondaryOverrides: buttonSecondaryOverrides(),
  logoOverrides: navLogoOverrides(),
  navOverrides: navOverrides(),
};
