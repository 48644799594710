import { GuestDataType } from "./utils";

export const wordData: { [key: string]: GuestDataType } = {
  DIETARY: {
    left: [
      "AD",
      "ED",
      "ID",
      "OD",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AR",
      "ER",
      "OR",
      "UR",
      "AY",
      "BY",
      "FY",
      "KY",
      "MY",
      "NY",
      "OY",
    ],
    right: [
      "DA",
      "DE",
      "DI",
      "DO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "TA",
      "TE",
      "TI",
      "TO",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "RE",
      "YA",
      "YE",
      "YO",
      "YU",
    ],
    essential: ["BY", "FY", "KY", "MY", "YU"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ES",
      "ET",
      "EX",
      "FA",
      "FE",
      "GO",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "JA",
      "JO",
      "KA",
      "KO",
      "KY",
      "LA",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "RE",
      "SH",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UP",
      "UT",
      "WE",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BE",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "NA",
      "NE",
      "NO",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OW",
      "OX",
      "OY",
      "RE",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "WE",
      "WO",
      "XI",
      "YE",
    ],
  },
  KERATIN: {
    left: [
      "OK",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AR",
      "ER",
      "OR",
      "UR",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
    ],
    right: [
      "KA",
      "KI",
      "KO",
      "KY",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "RE",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "TA",
      "TE",
      "TI",
      "TO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
    ],
    essential: ["KY"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AS",
      "AT",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ES",
      "ET",
      "EX",
      "FA",
      "FE",
      "GI",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IO",
      "IS",
      "IT",
      "JA",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OU",
      "OW",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "RE",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "US",
      "UT",
      "WE",
      "WO",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AD",
      "AE",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "GI",
      "GO",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OD",
      "OE",
      "OF",
      "OH",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "RE",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
    ],
  },
  NOTAIRE: {
    left: [
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "BO",
      "DO",
      "GO",
      "HO",
      "IO",
      "JO",
      "KO",
      "LO",
      "MO",
      "NO",
      "OO",
      "PO",
      "SO",
      "TO",
      "WO",
      "YO",
      "ZO",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AR",
      "ER",
      "OR",
      "UR",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
    ],
    right: [
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "TA",
      "TE",
      "TI",
      "TO",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "RE",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
    ],
    essential: [],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "GI",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "WE",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GO",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "KA",
      "KI",
      "KO",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "NA",
      "NE",
      "NO",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "RE",
      "SI",
      "SO",
      "ST",
      "TE",
      "TO",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
      "YU",
    ],
  },
  AILERON: {
    left: [
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AL",
      "EL",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AR",
      "ER",
      "OR",
      "UR",
      "BO",
      "DO",
      "GO",
      "HO",
      "IO",
      "JO",
      "KO",
      "LO",
      "MO",
      "NO",
      "OO",
      "PO",
      "SO",
      "TO",
      "WO",
      "YO",
      "ZO",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
    ],
    right: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LI",
      "LO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "RE",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
    ],
    essential: [],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GI",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "IO",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OS",
      "OU",
      "OW",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "RE",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "US",
      "UT",
      "WE",
      "WO",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AD",
      "AE",
      "AH",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "BA",
      "BE",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "GO",
      "HA",
      "HE",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "KA",
      "KO",
      "KY",
      "LO",
      "MA",
      "ME",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PO",
      "RE",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "WE",
      "WO",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
    ],
  },
  ELATION: {
    left: [
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AL",
      "EL",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "BO",
      "DO",
      "GO",
      "HO",
      "IO",
      "JO",
      "KO",
      "LO",
      "MO",
      "NO",
      "OO",
      "PO",
      "SO",
      "TO",
      "WO",
      "YO",
      "ZO",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
    ],
    right: [
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "LA",
      "LI",
      "LO",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "TA",
      "TE",
      "TI",
      "TO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
    ],
    essential: [],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ES",
      "ET",
      "EX",
      "FA",
      "FE",
      "GI",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "ID",
      "IO",
      "IS",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "KY",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OS",
      "OU",
      "OW",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "RE",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UP",
      "US",
      "UT",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EN",
      "ES",
      "ET",
      "FA",
      "FE",
      "FY",
      "GI",
      "GO",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "KA",
      "KO",
      "KY",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PO",
      "RE",
      "SH",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "WE",
      "WO",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
    ],
  },
  ERASION: {
    left: [
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AR",
      "ER",
      "OR",
      "UR",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AS",
      "ES",
      "IS",
      "OS",
      "US",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "BO",
      "DO",
      "GO",
      "HO",
      "IO",
      "JO",
      "KO",
      "LO",
      "MO",
      "NO",
      "OO",
      "PO",
      "SO",
      "TO",
      "WO",
      "YO",
      "ZO",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
    ],
    right: [
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "RE",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "SH",
      "SI",
      "SO",
      "ST",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
    ],
    essential: ["US", "SH"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ES",
      "ET",
      "EX",
      "FA",
      "FE",
      "GI",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "ID",
      "IO",
      "IS",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "KY",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "RE",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "US",
      "UT",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AE",
      "AH",
      "AI",
      "AT",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "GI",
      "GO",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "KA",
      "KO",
      "KY",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PO",
      "RE",
      "SH",
      "SI",
      "SO",
      "ST",
      "TE",
      "TI",
      "TO",
      "WE",
      "WO",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
    ],
  },
  ORIGANE: {
    left: [
      "BO",
      "DO",
      "GO",
      "HO",
      "IO",
      "JO",
      "KO",
      "LO",
      "MO",
      "NO",
      "OO",
      "PO",
      "SO",
      "TO",
      "WO",
      "YO",
      "ZO",
      "AR",
      "ER",
      "OR",
      "UR",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AG",
      "UG",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
    ],
    right: [
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "RE",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "GI",
      "GO",
      "GU",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
    ],
    essential: ["UG", "GU"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AD",
      "AE",
      "AH",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FE",
      "GO",
      "GU",
      "HA",
      "HE",
      "HO",
      "ID",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LO",
      "MA",
      "ME",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PE",
      "RE",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
    ],
  },
  GOATIER: {
    left: [
      "AG",
      "UG",
      "BO",
      "DO",
      "GO",
      "HO",
      "IO",
      "JO",
      "KO",
      "LO",
      "MO",
      "NO",
      "OO",
      "PO",
      "SO",
      "TO",
      "WO",
      "YO",
      "ZO",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AR",
      "ER",
      "OR",
      "UR",
    ],
    right: [
      "GI",
      "GO",
      "GU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "TA",
      "TE",
      "TI",
      "TO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "RE",
    ],
    essential: ["UG", "GU"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EH",
      "EL",
      "EM",
      "ES",
      "ET",
      "EX",
      "FA",
      "FE",
      "GO",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "JA",
      "KA",
      "KI",
      "KO",
      "KY",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OU",
      "OW",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "RE",
      "SH",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "US",
      "UT",
      "WE",
      "WO",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AD",
      "AE",
      "AI",
      "AN",
      "AS",
      "AT",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "GI",
      "GO",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "KA",
      "KI",
      "KY",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MY",
      "NA",
      "NE",
      "NO",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "RE",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
    ],
  },
  ANTIRED: {
    left: [
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AR",
      "ER",
      "OR",
      "UR",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AD",
      "ED",
      "ID",
      "OD",
    ],
    right: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "TA",
      "TE",
      "TI",
      "TO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "RE",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "DA",
      "DE",
      "DI",
      "DO",
    ],
    essential: [],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IO",
      "IS",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "RE",
      "SH",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AD",
      "AE",
      "AI",
      "AN",
      "AS",
      "AT",
      "AY",
      "BA",
      "BE",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OD",
      "OE",
      "OF",
      "OI",
      "ON",
      "OO",
      "OS",
      "RE",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "WE",
      "WO",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
    ],
  },
  DETRAIN: {
    left: [
      "AD",
      "ED",
      "ID",
      "OD",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AR",
      "ER",
      "OR",
      "UR",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
    ],
    right: [
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "TA",
      "TE",
      "TI",
      "TO",
      "RE",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
    ],
    essential: ["NY"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GI",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "RE",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UP",
      "UT",
      "WE",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AD",
      "AE",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "GO",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OD",
      "OE",
      "OF",
      "OH",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "RE",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "WE",
      "WO",
      "XI",
      "YO",
    ],
  },
  ENTRAIL: {
    left: [
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AR",
      "ER",
      "OR",
      "UR",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AL",
      "EL",
    ],
    right: [
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "TA",
      "TE",
      "TI",
      "TO",
      "RE",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LI",
      "LO",
    ],
    essential: [],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AS",
      "AT",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ES",
      "ET",
      "EX",
      "FA",
      "FE",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "IO",
      "IS",
      "JA",
      "JO",
      "KA",
      "KO",
      "LA",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OS",
      "OU",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "RE",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UP",
      "US",
      "UT",
      "WE",
      "WO",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AD",
      "AE",
      "AI",
      "AN",
      "AS",
      "AT",
      "AY",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "GO",
      "HA",
      "HE",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LO",
      "MA",
      "ME",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OE",
      "OI",
      "OO",
      "RE",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "WE",
      "WO",
      "XU",
      "YA",
      "YE",
      "YO",
    ],
  },
  LATRINE: {
    left: [
      "AL",
      "EL",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AR",
      "ER",
      "OR",
      "UR",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
    ],
    right: [
      "LA",
      "LI",
      "LO",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "TA",
      "TE",
      "TI",
      "TO",
      "RE",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
    ],
    essential: [],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GI",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IT",
      "JA",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "NA",
      "NE",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "XI",
      "XU",
      "YA",
      "YE",
      "YU",
      "ZA",
      "ZE",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "GI",
      "GO",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "RE",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
    ],
  },
  ANESTRI: {
    left: [
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AS",
      "ES",
      "IS",
      "OS",
      "US",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AR",
      "ER",
      "OR",
      "UR",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
    ],
    right: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "RE",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
    ],
    essential: ["US", "SH"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "RE",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AD",
      "AE",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HO",
      "IO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OW",
      "OX",
      "RE",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
    ],
  },
  ANTSIER: {
    left: [
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AS",
      "ES",
      "IS",
      "OS",
      "US",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AR",
      "ER",
      "OR",
      "UR",
    ],
    right: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "TA",
      "TE",
      "TI",
      "TO",
      "SH",
      "SI",
      "SO",
      "ST",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "RE",
    ],
    essential: ["US", "SH"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EH",
      "EL",
      "EM",
      "ES",
      "ET",
      "EX",
      "FA",
      "FE",
      "GO",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "ID",
      "IO",
      "IS",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "KY",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "RE",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UP",
      "US",
      "UT",
      "WE",
      "WO",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AE",
      "AH",
      "AI",
      "AT",
      "AY",
      "BA",
      "BE",
      "BO",
      "BY",
      "DA",
      "DE",
      "DO",
      "EA",
      "EE",
      "EH",
      "ET",
      "FA",
      "FE",
      "GO",
      "GU",
      "HA",
      "HE",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LO",
      "MA",
      "ME",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OW",
      "OX",
      "RE",
      "SH",
      "SO",
      "ST",
      "TA",
      "TE",
      "TO",
      "WE",
      "WO",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
    ],
  },
  RUINATE: {
    left: [
      "AR",
      "ER",
      "OR",
      "UR",
      "GU",
      "MU",
      "NU",
      "OU",
      "XU",
      "YU",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
    ],
    right: [
      "RE",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "TA",
      "TE",
      "TI",
      "TO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
    ],
    essential: ["GU", "MU", "XU", "YU", "UG", "UH", "UM", "UP", "US"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GI",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IT",
      "JA",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OS",
      "OU",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "UT",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "GI",
      "GO",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PO",
      "RE",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
    ],
  },
  DARIOLE: {
    left: [
      "AD",
      "ED",
      "ID",
      "OD",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AR",
      "ER",
      "OR",
      "UR",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "BO",
      "DO",
      "GO",
      "HO",
      "IO",
      "JO",
      "KO",
      "LO",
      "MO",
      "NO",
      "OO",
      "PO",
      "SO",
      "TO",
      "WO",
      "YO",
      "ZO",
      "AL",
      "EL",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
    ],
    right: [
      "DA",
      "DE",
      "DI",
      "DO",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "RE",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "LA",
      "LI",
      "LO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
    ],
    essential: [],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GI",
      "GO",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "ID",
      "IF",
      "IN",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "KY",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OS",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UP",
      "US",
      "UT",
      "WE",
      "XI",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "KA",
      "KI",
      "KO",
      "MA",
      "ME",
      "MI",
      "MO",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PI",
      "PO",
      "RE",
      "SH",
      "SI",
      "SO",
      "TE",
      "TI",
      "TO",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
    ],
  },
  IODATES: {
    left: [
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "BO",
      "DO",
      "GO",
      "HO",
      "IO",
      "JO",
      "KO",
      "LO",
      "MO",
      "NO",
      "OO",
      "PO",
      "SO",
      "TO",
      "WO",
      "YO",
      "ZO",
      "AD",
      "ED",
      "ID",
      "OD",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AS",
      "ES",
      "IS",
      "OS",
      "US",
    ],
    right: [
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "DA",
      "DE",
      "DI",
      "DO",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "TA",
      "TE",
      "TI",
      "TO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "SH",
      "SI",
      "SO",
      "ST",
    ],
    essential: ["US", "SH"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "FA",
      "FE",
      "GI",
      "GO",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "KY",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OS",
      "OU",
      "OW",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "RE",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "UT",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "GI",
      "GO",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "RE",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
    ],
  },
  RETINOL: {
    left: [
      "AR",
      "ER",
      "OR",
      "UR",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "BO",
      "DO",
      "GO",
      "HO",
      "IO",
      "JO",
      "KO",
      "LO",
      "MO",
      "NO",
      "OO",
      "PO",
      "SO",
      "TO",
      "WO",
      "YO",
      "ZO",
      "AL",
      "EL",
    ],
    right: [
      "RE",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "TA",
      "TE",
      "TI",
      "TO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "LA",
      "LI",
      "LO",
    ],
    essential: [],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GI",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IN",
      "IO",
      "IS",
      "IT",
      "JA",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "NA",
      "NE",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "RE",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "WE",
      "XI",
      "XU",
      "YA",
      "YE",
      "YU",
      "ZA",
      "ZE",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AH",
      "AI",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PI",
      "PO",
      "RE",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
      "YU",
    ],
  },
  GRANITE: {
    left: [
      "AG",
      "UG",
      "AR",
      "ER",
      "OR",
      "UR",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
    ],
    right: [
      "GI",
      "GO",
      "GU",
      "RE",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "TA",
      "TE",
      "TI",
      "TO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
    ],
    essential: ["UG", "GU"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GI",
      "GU",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OS",
      "OU",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AD",
      "AE",
      "AH",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GO",
      "GU",
      "HA",
      "HE",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LO",
      "MA",
      "ME",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OW",
      "OX",
      "RE",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UN",
      "US",
      "UT",
      "WE",
      "WO",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
    ],
  },
  NIOBATE: {
    left: [
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "BO",
      "DO",
      "GO",
      "HO",
      "IO",
      "JO",
      "KO",
      "LO",
      "MO",
      "NO",
      "OO",
      "PO",
      "SO",
      "TO",
      "WO",
      "YO",
      "ZO",
      "AB",
      "OB",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
    ],
    right: [
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "TA",
      "TE",
      "TI",
      "TO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
    ],
    essential: ["BY", "WO"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GI",
      "GO",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "KY",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OS",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "GI",
      "GO",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "KA",
      "KI",
      "KO",
      "KY",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "RE",
      "SH",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UN",
      "US",
      "UT",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
    ],
  },
  MORAINE: {
    left: [
      "AM",
      "EM",
      "HM",
      "MM",
      "OM",
      "UM",
      "BO",
      "DO",
      "GO",
      "HO",
      "IO",
      "JO",
      "KO",
      "LO",
      "MO",
      "NO",
      "OO",
      "PO",
      "SO",
      "TO",
      "WO",
      "YO",
      "ZO",
      "AR",
      "ER",
      "OR",
      "UR",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
    ],
    right: [
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "MY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "RE",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
    ],
    essential: ["HM", "MM", "UM", "MU", "MY"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "GI",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BE",
      "BO",
      "DA",
      "DE",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "GO",
      "HE",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LO",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PE",
      "RE",
      "SO",
      "ST",
      "TA",
      "TE",
      "TO",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "YA",
      "YE",
      "YO",
      "YU",
    ],
  },
  DILATER: {
    left: [
      "AD",
      "ED",
      "ID",
      "OD",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AL",
      "EL",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AR",
      "ER",
      "OR",
      "UR",
    ],
    right: [
      "DA",
      "DE",
      "DI",
      "DO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LI",
      "LO",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "TA",
      "TE",
      "TI",
      "TO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "RE",
    ],
    essential: [],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "JA",
      "KA",
      "KI",
      "LA",
      "LI",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OH",
      "OK",
      "OM",
      "OO",
      "OP",
      "OS",
      "OU",
      "OX",
      "PA",
      "PE",
      "PI",
      "PO",
      "RE",
      "SH",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "UT",
      "WE",
      "WO",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GI",
      "GO",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "NA",
      "NE",
      "NO",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OW",
      "OX",
      "OY",
      "RE",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
    ],
  },
  RANDIES: {
    left: [
      "AR",
      "ER",
      "OR",
      "UR",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AD",
      "ED",
      "ID",
      "OD",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AS",
      "ES",
      "IS",
      "OS",
      "US",
    ],
    right: [
      "RE",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "DA",
      "DE",
      "DI",
      "DO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "SH",
      "SI",
      "SO",
      "ST",
    ],
    essential: ["US", "SH"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ES",
      "ET",
      "EX",
      "FA",
      "FE",
      "GO",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "ID",
      "IO",
      "IS",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "KY",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "RE",
      "SH",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UP",
      "UT",
      "WE",
      "WO",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AD",
      "AE",
      "AH",
      "AI",
      "AN",
      "AS",
      "AT",
      "AY",
      "BA",
      "BE",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OW",
      "OX",
      "RE",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "WE",
      "WO",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
    ],
  },
  DIATRON: {
    left: [
      "AD",
      "ED",
      "ID",
      "OD",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AR",
      "ER",
      "OR",
      "UR",
      "BO",
      "DO",
      "GO",
      "HO",
      "IO",
      "JO",
      "KO",
      "LO",
      "MO",
      "NO",
      "OO",
      "PO",
      "SO",
      "TO",
      "WO",
      "YO",
      "ZO",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
    ],
    right: [
      "DA",
      "DE",
      "DI",
      "DO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "TA",
      "TE",
      "TI",
      "TO",
      "RE",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
    ],
    essential: [],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EH",
      "EL",
      "EM",
      "EN",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GI",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "ID",
      "IO",
      "IS",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "KY",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "US",
      "UT",
      "WE",
      "WO",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AD",
      "AE",
      "AI",
      "AN",
      "AS",
      "AT",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "GI",
      "GO",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "KA",
      "KO",
      "KY",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PO",
      "RE",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "WE",
      "WO",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
    ],
  },
  ATEBRIN: {
    left: [
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AB",
      "OB",
      "AR",
      "ER",
      "OR",
      "UR",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
    ],
    right: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "TA",
      "TE",
      "TI",
      "TO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "RE",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
    ],
    essential: ["BY"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GI",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IT",
      "JA",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OH",
      "OI",
      "OK",
      "OM",
      "OO",
      "OP",
      "OS",
      "OU",
      "OX",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "WO",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AD",
      "AE",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "GI",
      "GO",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OD",
      "OE",
      "OF",
      "OH",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "RE",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
    ],
  },
  FENITAR: {
    left: [
      "EF",
      "IF",
      "OF",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AR",
      "ER",
      "OR",
      "UR",
    ],
    right: [
      "FA",
      "FE",
      "FY",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "TA",
      "TE",
      "TI",
      "TO",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "RE",
    ],
    essential: ["FY"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AS",
      "AT",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GI",
      "GU",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "RE",
      "SH",
      "SI",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "US",
      "UT",
      "WE",
      "WO",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BE",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "HA",
      "HE",
      "HO",
      "ID",
      "IN",
      "IS",
      "IT",
      "LA",
      "LO",
      "MA",
      "ME",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OW",
      "OX",
      "OY",
      "RE",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UN",
      "US",
      "UT",
      "WE",
      "WO",
      "XU",
      "YA",
      "YE",
      "YO",
    ],
  },
  HAIRNET: {
    left: [
      "AH",
      "CH",
      "EH",
      "OH",
      "SH",
      "UH",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AR",
      "ER",
      "OR",
      "UR",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
    ],
    right: [
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "RE",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "TA",
      "TE",
      "TI",
      "TO",
    ],
    essential: ["CH", "SH", "UH", "HM"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "CH",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IT",
      "JA",
      "JO",
      "KA",
      "KO",
      "LA",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "RE",
      "SH",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "UT",
      "WE",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GO",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MY",
      "NA",
      "NE",
      "NO",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OR",
      "OS",
      "OW",
      "OX",
      "OY",
      "RE",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
    ],
  },
  MERANTI: {
    left: [
      "AM",
      "EM",
      "HM",
      "MM",
      "OM",
      "UM",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AR",
      "ER",
      "OR",
      "UR",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
    ],
    right: [
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "MY",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "RE",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "TA",
      "TE",
      "TI",
      "TO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
    ],
    essential: ["HM", "MM", "UM", "MU", "MY"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "ID",
      "IO",
      "IS",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "RE",
      "SH",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AD",
      "AE",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BO",
      "BY",
      "DA",
      "DE",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FE",
      "GO",
      "GU",
      "HA",
      "HE",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LO",
      "MA",
      "ME",
      "MM",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OW",
      "OX",
      "RE",
      "SO",
      "TA",
      "TE",
      "TO",
      "UH",
      "UM",
      "UN",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
    ],
  },
  INAPTER: {
    left: [
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "OP",
      "UP",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AR",
      "ER",
      "OR",
      "UR",
    ],
    right: [
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "PA",
      "PE",
      "PI",
      "PO",
      "TA",
      "TE",
      "TI",
      "TO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "RE",
    ],
    essential: ["UP"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AT",
      "AW",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ET",
      "EW",
      "FA",
      "FE",
      "GI",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IT",
      "JO",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OS",
      "OU",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "RE",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "GI",
      "GO",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "RE",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
    ],
  },
  TAWNIER: {
    left: [
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AW",
      "EW",
      "OW",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AR",
      "ER",
      "OR",
      "UR",
    ],
    right: [
      "TA",
      "TE",
      "TI",
      "TO",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "WE",
      "WO",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "RE",
    ],
    essential: [],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BO",
      "BY",
      "DA",
      "DE",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ES",
      "ET",
      "EX",
      "FA",
      "FE",
      "GO",
      "HA",
      "HE",
      "HM",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "JA",
      "JO",
      "KA",
      "KO",
      "KY",
      "LA",
      "LO",
      "MA",
      "ME",
      "MM",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PO",
      "RE",
      "SH",
      "SO",
      "ST",
      "TA",
      "TE",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "US",
      "UT",
      "WE",
      "WO",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BE",
      "BO",
      "DA",
      "DE",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GO",
      "HE",
      "HO",
      "ID",
      "IN",
      "IO",
      "IS",
      "IT",
      "LO",
      "ME",
      "MO",
      "NA",
      "NE",
      "NO",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "RE",
      "SH",
      "SO",
      "ST",
      "TA",
      "TE",
      "TO",
      "UN",
      "US",
      "UT",
      "WE",
      "WO",
      "YE",
      "YO",
    ],
  },
  DARNEST: {
    left: [
      "AD",
      "ED",
      "ID",
      "OD",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AR",
      "ER",
      "OR",
      "UR",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AS",
      "ES",
      "IS",
      "OS",
      "US",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
    ],
    right: [
      "DA",
      "DE",
      "DI",
      "DO",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "RE",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
    ],
    essential: ["US", "SH"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "FA",
      "FE",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "JA",
      "JO",
      "KA",
      "KO",
      "LA",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "RE",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BE",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IN",
      "IO",
      "IS",
      "IT",
      "LI",
      "LO",
      "ME",
      "MI",
      "MO",
      "NE",
      "NO",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OW",
      "OX",
      "OY",
      "RE",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
    ],
  },
  TENDRIL: {
    left: [
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AD",
      "ED",
      "ID",
      "OD",
      "AR",
      "ER",
      "OR",
      "UR",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AL",
      "EL",
    ],
    right: [
      "TA",
      "TE",
      "TI",
      "TO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "DA",
      "DE",
      "DI",
      "DO",
      "RE",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LI",
      "LO",
    ],
    essential: [],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IT",
      "KA",
      "LA",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OH",
      "OI",
      "OK",
      "OM",
      "OO",
      "OP",
      "OS",
      "OU",
      "OX",
      "PA",
      "PE",
      "PI",
      "PO",
      "RE",
      "SH",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AD",
      "AE",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "HA",
      "HE",
      "HO",
      "ID",
      "IF",
      "IN",
      "IS",
      "IT",
      "LA",
      "LO",
      "MA",
      "ME",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OW",
      "OX",
      "RE",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "WE",
      "WO",
      "XU",
      "YA",
      "YE",
      "YO",
    ],
  },
  INTRUDE: {
    left: [
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AT",
      "ET",
      "IT",
      "ST",
      "UT",
      "AR",
      "ER",
      "OR",
      "UR",
      "GU",
      "MU",
      "NU",
      "OU",
      "XU",
      "YU",
      "AD",
      "ED",
      "ID",
      "OD",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
    ],
    right: [
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "TA",
      "TE",
      "TI",
      "TO",
      "RE",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
    ],
    essential: ["GU", "MU", "XU", "YU", "UG", "UH", "UM", "UP", "US"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "GI",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UM",
      "UN",
      "US",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
    ],
    rightAdj: [
      "AA",
      "AD",
      "AE",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "DA",
      "DE",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "GI",
      "GO",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IS",
      "IT",
      "MA",
      "ME",
      "MI",
      "MO",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "RE",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "YA",
      "YE",
      "YO",
    ],
  },
  ORDINAL: {
    left: [
      "BO",
      "DO",
      "GO",
      "HO",
      "IO",
      "JO",
      "KO",
      "LO",
      "MO",
      "NO",
      "OO",
      "PO",
      "SO",
      "TO",
      "WO",
      "YO",
      "ZO",
      "AR",
      "ER",
      "OR",
      "UR",
      "AD",
      "ED",
      "ID",
      "OD",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
      "AL",
      "EL",
    ],
    right: [
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "RE",
      "DA",
      "DE",
      "DI",
      "DO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "LA",
      "LI",
      "LO",
    ],
    essential: ["QI"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AS",
      "AT",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ES",
      "ET",
      "EX",
      "FA",
      "FE",
      "GI",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OS",
      "OU",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UP",
      "UT",
      "WE",
      "WO",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "GI",
      "GO",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OM",
      "ON",
      "OO",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PE",
      "RE",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
    ],
  },
  DOLINES: {
    left: [
      "AD",
      "ED",
      "ID",
      "OD",
      "BO",
      "DO",
      "GO",
      "HO",
      "IO",
      "JO",
      "KO",
      "LO",
      "MO",
      "NO",
      "OO",
      "PO",
      "SO",
      "TO",
      "WO",
      "YO",
      "ZO",
      "AL",
      "EL",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AS",
      "ES",
      "IS",
      "OS",
      "US",
    ],
    right: [
      "DA",
      "DE",
      "DI",
      "DO",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "LA",
      "LI",
      "LO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "SH",
      "SI",
      "SO",
      "ST",
    ],
    essential: ["US", "SH", "OX"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "GI",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "JA",
      "KA",
      "KI",
      "KO",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "QI",
      "RE",
      "SI",
      "SO",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "UT",
      "WE",
      "WO",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BI",
      "BO",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "HA",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "KA",
      "KI",
      "KO",
      "LI",
      "LO",
      "MA",
      "MI",
      "MO",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PI",
      "PO",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UR",
      "US",
      "UT",
      "WO",
      "XI",
      "YA",
      "YE",
      "YO",
    ],
  },
  BEGONIA: {
    left: [
      "AB",
      "OB",
      "AE",
      "BE",
      "DE",
      "EE",
      "FE",
      "HE",
      "ME",
      "NE",
      "OE",
      "PE",
      "RE",
      "TE",
      "WE",
      "YE",
      "ZE",
      "AG",
      "UG",
      "BO",
      "DO",
      "GO",
      "HO",
      "IO",
      "JO",
      "KO",
      "LO",
      "MO",
      "NO",
      "OO",
      "PO",
      "SO",
      "TO",
      "WO",
      "YO",
      "ZO",
      "AN",
      "EN",
      "IN",
      "ON",
      "UN",
      "AI",
      "BI",
      "DI",
      "GI",
      "HI",
      "KI",
      "LI",
      "MI",
      "OI",
      "PI",
      "QI",
      "SI",
      "TI",
      "XI",
      "AA",
      "BA",
      "DA",
      "EA",
      "FA",
      "HA",
      "JA",
      "KA",
      "LA",
      "MA",
      "NA",
      "PA",
      "TA",
      "YA",
      "ZA",
    ],
    right: [
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "GI",
      "GO",
      "GU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
    ],
    essential: ["UG", "BY", "GU", "WO"],
    leftAdj: [
      "AA",
      "AB",
      "AD",
      "AE",
      "AG",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "AY",
      "BA",
      "BE",
      "BI",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EH",
      "EL",
      "EM",
      "ES",
      "ET",
      "EX",
      "FA",
      "GI",
      "GO",
      "GU",
      "HA",
      "HE",
      "HI",
      "HM",
      "HO",
      "ID",
      "IO",
      "IS",
      "IT",
      "JA",
      "JO",
      "KA",
      "KI",
      "KO",
      "KY",
      "LA",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MM",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PI",
      "PO",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UG",
      "UH",
      "UM",
      "UN",
      "UP",
      "US",
      "UT",
      "WE",
      "WO",
      "YA",
      "YE",
      "YO",
      "YU",
      "ZA",
      "ZE",
      "ZO",
    ],
    rightAdj: [
      "AA",
      "AD",
      "AE",
      "AH",
      "AI",
      "AL",
      "AM",
      "AN",
      "AR",
      "AS",
      "AT",
      "AW",
      "AX",
      "BA",
      "BE",
      "BO",
      "BY",
      "DA",
      "DE",
      "DI",
      "DO",
      "EA",
      "ED",
      "EE",
      "EF",
      "EH",
      "EL",
      "EM",
      "EN",
      "ER",
      "ES",
      "ET",
      "EW",
      "EX",
      "FA",
      "FE",
      "FY",
      "HA",
      "HE",
      "HI",
      "HO",
      "ID",
      "IF",
      "IN",
      "IO",
      "IS",
      "IT",
      "KA",
      "KO",
      "KY",
      "LI",
      "LO",
      "MA",
      "ME",
      "MI",
      "MO",
      "MU",
      "MY",
      "NA",
      "NE",
      "NO",
      "NU",
      "NY",
      "OB",
      "OD",
      "OE",
      "OF",
      "OH",
      "OI",
      "OK",
      "OM",
      "ON",
      "OO",
      "OP",
      "OR",
      "OS",
      "OU",
      "OW",
      "OX",
      "OY",
      "PA",
      "PE",
      "PO",
      "RE",
      "SH",
      "SI",
      "SO",
      "ST",
      "TA",
      "TE",
      "TI",
      "TO",
      "UH",
      "UM",
      "UN",
      "UP",
      "UR",
      "US",
      "UT",
      "WE",
      "WO",
      "XI",
      "XU",
      "YA",
      "YE",
      "YO",
      "YU",
    ],
  },
};
