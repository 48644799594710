import {
  countOccurrences,
  filterToUnique,
  randomSelect,
} from "../_shared/utils";
import { twos } from "../../data/2";
import { wordData as compiledWordData } from "./wordData";

export const findUniqueMatchedWords = (
  word: string,
  wordList: string[] = twos,
  rightSide?: boolean
) => {
  const tiles = word.split("");
  const wordsFormed = new Array<string>();
  const lettersUsed = new Array<string>();
  tiles.forEach((tile) => {
    const words = wordList.filter(
      (w) => w.split("")[rightSide ? 0 : 1] === tile
    );
    words.forEach((w) => {
      lettersUsed.push(w.split("")[rightSide ? 1 : 0]);
      wordsFormed.push(w);
    });
  });
  const uniqueUsed = filterToUnique(lettersUsed);
  const uniqueUsedWords = uniqueUsed.map(
    (u) => wordsFormed[lettersUsed.indexOf(u)]
  );
  return uniqueUsedWords;
};

export const findMatchedWords = (
  word: string,
  wordList: string[] = twos,
  rightSide: boolean
) => {
  const tiles = word.split("");
  const wordsFormed = new Array<string>();
  const lettersUsed = new Array<string>();
  tiles.forEach((tile) => {
    const words = wordList.filter(
      (w) => w.split("")[rightSide ? 0 : 1] === tile
    );
    words.forEach((w) => {
      lettersUsed.push(w.split("")[rightSide ? 1 : 0]);
      wordsFormed.push(w);
    });
  });
  return wordsFormed;
};

export const minimalWordList = [
  "DIETARY",
  "KERATIN",
  "NOTAIRE",
  "AILERON",
  "ELATION",
  "ERASION",
  "ORIGANE",
  "GOATIER",
  "ANTIRED",
  "DETRAIN",
  "ENTRAIL",
  "LATRINE",
  "ANESTRI",
  "ANTSIER",
  "RUINATE",
  "DARIOLE",
  "IODATES",
  "RETINOL",
  "GRANITE",
  "NIOBATE",
  "MORAINE",
  "DILATER",
  "RANDIES",
  "DIATRON",
  "ATEBRIN",
  "FENITAR",
  "HAIRNET",
  "MERANTI",
  "INAPTER",
  "TAWNIER",
  "DARNEST",
  "TENDRIL",
  "INTRUDE",
  "ORDINAL",
  "DOLINES",
  "BEGONIA",
];

export type GuestDataType = {
  left: Array<string>;
  right: Array<string>;
  essential: Array<string>;
  leftAdj: Array<string>;
  rightAdj: Array<string>;
};

export const wordData = {} as { [key: string]: GuestDataType };

export const findEssentialMatches = (
  guestData: { [key: string]: GuestDataType },
  minCount = 1
): string[] => {
  const allValues = [] as string[];
  for (const word in guestData) {
    allValues.push(...guestData[word].left, ...guestData[word].right);
  }
  const counts = countOccurrences(allValues);
  return allValues.filter((w) => counts[w] <= minCount);
};

export const findSegment = (
  percent: number,
  segmentCount: number
): number | null => {
  if (percent < 0 || percent > 100) return null;
  return Math.floor((percent / 100) * segmentCount) + 1;
};

export const irreversible = (words = twos) =>
  twos.filter((w) => !twos.includes(w.split("").reverse().join("")));

export const filterAdjacentTwos = (
  word: string,
  matches: string[],
  left: boolean = false,
  words: string[] = twos
): string[] => {
  const matchPositions = matches.map((match) =>
    word.indexOf(match.charAt(left ? 1 : 0))
  );
  // Filter twos that can be formed by adjacent matches

  return words.filter((two) => {
    let valid = false;
    for (let i = 0; i < matchPositions.length; i++) {
      const pos = matchPositions[i];
      // Check if the previous character in 'word' forms a two-letter word with current match
      // QI DIETARY [1]

      const wordsFormed = left
        ? [`${two[0]}${word[pos]}`, `${two[1]}${word[pos + 1]}`]
        : [`${word[pos]}${two[0]}`, `${word[pos + 1]}${two[1]}`];

      if (
        wordsFormed.filter((w) => words.includes(w)).length ===
        wordsFormed.length
      ) {
        valid = true;
        break;
      }
    }
    return valid;
  });
};
/*minimalWordList.forEach((w) => {
  const left = findMatchedWords(w, twos, false);
  const right = findMatchedWords(w, twos, true);
  wordData[w] = {
    left,
    right,
    essential: [],
    leftAdj: [],
    rightAdj: [],
  };
});

minimalWordList.forEach((w) => {
  wordData[w].essential = Array.from(
    new Set([...wordData[w].left, ...wordData[w].right])
  ).filter((w) => findEssentialMatches(wordData, 12).includes(w));
});

minimalWordList.forEach((w) => {
  wordData[w].essential = Array.from(
    new Set([...wordData[w].left, ...wordData[w].right])
  ).filter((w) => findEssentialMatches(wordData, 12).includes(w));
});

minimalWordList.forEach((w) => {
  wordData[w].leftAdj = filterAdjacentTwos(w, wordData[w].left, true, twos);
  wordData[w].rightAdj = filterAdjacentTwos(w, wordData[w].right, false, twos);
});*/
export type OrderUpType = {
  forbidden: number[];
  word: string;
  tile: string;
};

//Returns the selected orders and an array of forbidden if necessary, requiring the words in essentials
export const getOrdersUp = (
  word: string,
  config: {
    perSide: number; //goal, not guaranteed
    maxParallel: number;
    wordData: { [key: string]: GuestDataType };
  } = {
    perSide: 7,
    maxParallel: 0,
    wordData: compiledWordData,
  },
  additionalEssentials: string[] = []
): {
  ordersUpLeft: OrderUpType[];
  ordersUpRight: OrderUpType[];
} => {
  const data = config.wordData[word];
  const { essential, left, right, leftAdj, rightAdj } = data;
  let response = {
    ordersUpLeft: [] as OrderUpType[],
    ordersUpRight: [] as OrderUpType[],
  };
  essential.push(...additionalEssentials);
  essential.forEach((e) => {
    // check each side for a match to e
    // if so, add an entry for it to the response
    // containing the indexes that make other valid words
    if (left.includes(e)) {
      const forbidden = <number[]>[];
      const tile = e[0];
      word.split("").forEach((l, i) => {
        if (left.includes(`${tile}${l}`) && e !== `${tile}${l}`) {
          forbidden.push(i);
        }
      });
      response.ordersUpLeft.push({ word, tile, forbidden });
    }

    if (right.includes(e)) {
      const forbidden = <number[]>[];
      const tile = e[1];
      word.split("").forEach((l, i) => {
        if (right.includes(`${l}${tile}`) && e !== `${l}${tile}`) {
          forbidden.push(i);
        }
      });
      response.ordersUpRight.push({ word, tile, forbidden });
    }
    const leftAdjPool = new Set(leftAdj);
    const rightAdjPool = new Set(rightAdj);
    for (let i = response.ordersUpLeft?.length + 1; i < config.perSide; i++) {
      const adjCount = Object.values(response.ordersUpLeft).filter(
        (w) => w.tile.length > 1
      ).length;
      const toAdd =
        adjCount < config.maxParallel
          ? randomSelect(leftAdjPool)
          : randomSelect(left)?.charAt(0);
      if (toAdd) {
        response.ordersUpLeft.push({ word, tile: toAdd, forbidden: [] });
      }
    }
    for (let i = response.ordersUpRight?.length + 1; i < config.perSide; i++) {
      const adjCount = Object.values(response.ordersUpRight).filter(
        (w) => w.tile.length > 1
      ).length;
      const toAdd =
        adjCount < config.maxParallel
          ? randomSelect(rightAdjPool)
          : randomSelect(right)?.charAt(1);
      if (toAdd) {
        response.ordersUpRight.push({ word, tile: toAdd, forbidden: [] });
      }
    }
  });
  return response;
};

//console.warn(wordData);
console.warn(
  getOrdersUp("KERATIN", {
    maxParallel: 3,
    perSide: 7,
    wordData: compiledWordData,
  })
);
