import React, { useEffect } from "react";
import small1 from "./small_1.png";
import small2 from "./small_2.png";
import med1 from "./med_1.png";
import med2 from "./med_2.png";
import large1 from "./large_1.png";
import large2 from "./large_2.png";
import small1b from "./small_1_b.png";
import small2b from "./small_2_b.png";
import med1b from "./med_1_b.png";
import med2b from "./med_2_b.png";
import large1b from "./large_1_b.png";
import large2b from "./large_2_b.png";
import med1c from "./med_1_c.png";
import med2c from "./med_2_c.png";
import large1c from "./large_1_c.png";
import large2c from "./large_2_c.png";
import large1d from "./large_1_d.png";
import large2d from "./large_2_d.png";
import styled from "styled-components";
import { maxWords } from "../fourtress";
import { rem } from "polished";

type Images = {
  0: (string | undefined)[];
  1: (string | undefined)[];
  2: (string | undefined)[];
};
const fortresses: Images = {
  0: [small1, small2],
  1: [med1, med2],
  2: [large1, large2],
};

const broken1: Images = {
  0: [small1b, small2b],
  1: [med1b, med2b],
  2: [large1b, large2b],
};

const broken2: Images = {
  0: [undefined, undefined],
  1: [med1c, med2c],
  2: [large1c, large2c],
};

const broken3: Images = {
  0: [undefined, undefined],
  1: [undefined, undefined],
  2: [large1d, large2d],
};

const Fort = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vw;
`;

const FlippedFort = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vw;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`;
type FortressProps = {
  totalCount: number;
  remainingCount: number;
  flip: boolean;
};
export const Fortress = ({
  totalCount,
  remainingCount,
  flip,
}: FortressProps) => {
  const [images, setImages] = React.useState<Images>(fortresses);
  const size =
    totalCount < maxWords / 3 ? 0 : totalCount < (maxWords * 2) / 3 ? 1 : 2;

  useEffect(() => {
    let temp: Images = fortresses;
    if (remainingCount == totalCount) {
      setImages(temp);
    } else {
      switch (size) {
        case 2:
          if (remainingCount < totalCount / 4) {
            temp = broken3;
            console.log("broken3");
          } else if (remainingCount <= totalCount / 2) {
            temp = broken2;
            console.log("broken2");
          } else if (remainingCount <= (3 * totalCount) / 4) {
            temp = broken1;
            console.log("broken1");
          }
          break;
        case 1:
          if (remainingCount < totalCount / 3) {
            temp = broken2;
            console.log("broken2");
          } else if (remainingCount < (2 * totalCount) / 3) {
            temp = broken1;
            console.log("broken1");
          }
          break;
        default:
          if (remainingCount < totalCount / 2) {
            temp = broken1;
            console.log("broken1");
          }
      }
      setImages(temp);
    }
    console.log(size, remainingCount, totalCount);
  }, [remainingCount, size, totalCount]);

  return remainingCount === 0 ? null : flip ? (
    <FlippedFort src={images[size][totalCount % 2]} />
  ) : (
    <Fort src={images[size][totalCount % 2]} />
  );
};
