import React, { useMemo } from "react";
import { CellType, isFront } from "../utils";
import { CellSet } from "../hyperspace";
import styled from "styled-components";
import { NeonGlow } from "../../../styles/effects";

const CollectionEl = styled.div`
  display: flex;
  position: fixed;
  z-index: 4;
  color: #793f86;
  text-align: center;
  margin: -1vh;

  filter: blur(0vw) !important;

  p {
    margin: 2vh 0;
    font-family: "Gabarito", monospace;
    font-weight: 200;
    letter-spacing: 0.1em;
    font-size: 1.5vh;
    width: 7vh;
    line-height: 0;
    text-align: center;
    filter: blur(0vw) !important;

    &.collected {
      font-weight: 700;
      color: rgb(231, 96, 213);
      font-size: 1.7vh;
      ${NeonGlow("rgba(234,170,255,0.09)", 1, true)}
      position: relative;
      left: 0.2vh;
    }
  }

  &.top {
    width: 100%;
    max-width: 80%;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 30%;
    transform: translatex(-15%);

    p {
      margin: 1.5vh 0;
    }
  }

  &.left {
    left: 1.5vh;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }

  &.right {
    right: 0;
    top: 0;
    transform: translatex(-15%);
    height: 100%;
    text-align: right;
    flex-direction: column;
    justify-content: center;
  }

  &.bottom {
    bottom: 2vh;
    width: 100%;
    max-width: 80%;
    padding: 0 30%;
    transform: translatex(-15%);
    flex-wrap: wrap;
    justify-content: center;

    p {
      margin: 1.5vh 0;
    }
  }
`;
const Collection = ({ cell, pos }: CollectionProps) => {
  const { collectedRunes, solution } = cell;
  const solutions = useMemo(
    () =>
      [...Array.from(solution?.runes || "")].map((r, i) => {
        const rune = collectedRunes.includes(r) ? r : "_";
        return isFront(pos) ? (
          <p key={i} className={collectedRunes.includes(r) ? "collected" : ""}>
            {rune}
            {solution!.word}
          </p>
        ) : (
          <p key={i} className={collectedRunes.includes(r) ? "collected" : ""}>
            {solution!.word}
            {rune}
          </p>
        );
      }),
    [pos, solution, collectedRunes]
  );
  return (
    <CollectionEl className={`collection ${pos}`}>{solutions}</CollectionEl>
  );
};

type CollectionProps = {
  cell: CellType;
  pos: keyof CellSet;
};

export default Collection;
