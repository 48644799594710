import React, { useState } from "react";
import { CellType } from "../utils";
import styled from "styled-components";
import { FadeIn, FadeOut, FlickerIn, NeonGlow } from "../../../styles/effects";
import { MatchResult } from "../hyperspace";
import { configDefaults as config } from "../config";
import { getLockDelay } from "./Incoming";

type CellBlockElProps = {
  speed?: number;
};

const CellBlockEl = styled.span<CellBlockElProps>`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  border: 1px solid #5e1755;
  font-size: 3.5vh;
  font-weight: 600;
  color: white;
  width: 5vh;
  height: 5vh;
  aspect-ratio: 1;
  padding: 0.3vh;
  background-color: rgba(74, 33, 134, 0.07);

  ${NeonGlow("rgba(208,179,213,0.2)", 0.2, true)}
  ${NeonGlow("rgba(55,27,103,0.1)", 4, false)}
  .counter {
    display: block;
    text-shadow: none;
    font-size: 1.2vw;
    color: #a2a2a2;
    padding: 0.4vh;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .preview {
    font-weight: 400;
    color: #777676;
    font-size: 95%;
    text-shadow: none;
    opacity: 0;
    animation: ${FlickerIn} 1s ease-out forwards;
    animation-duration: ${({ speed }) =>
      Math.round((speed || 1) * config.PHASE_TIMING.NORMAL) / 200}ms;
    animation-delay: ${({ speed }) => getLockDelay(speed || 1)}ms;
  }
`;

export const ErrorCellBlock = styled(CellBlockEl)`
  color: #b02c3b;
  border-color: #b02c3b;
  opacity: 0;
  text-shadow: none;
  animation: ${FadeOut} 2s linear;
`;

export const BlockedBlock = styled(CellBlockEl)`
  box-shadow: none;
  text-shadow: none;
  color: #8c8b8b;
  font-weight: 200;
  border-color: gray;
  .preview {
    font-weight: 400;
    color: #333;
    text-shadow: none;
    opacity: 0;
    animation: ${FadeIn}
      ${({ speed }) => (speed || 1) * config.PHASE_TIMING.NORMAL * 5} forwards;
    animation-delay: ${({ speed }) => getLockDelay(speed || 1)}ms;
  }
`;

export const MatchBlock = styled(CellBlockEl)`
  ${NeonGlow("rgba(207,139,234,0.17)", 1, true)}
  ${NeonGlow("rgba(72,40,134,0.2)", 2, false)}
  color: #ffffff;
  border-color: #a974e3;
  opacity: 1;
  transition: color 1s, text-shadow 1s;
  animation: ${FlickerIn} 0.2s linear forwards;
`;

type CellBlockProps = {
  rune?: string;
  error?: boolean;
  count?: number;
  alreadyCollected?: boolean;
  matched?: boolean;
  previewRune?: string;
  speed?: number;
};

export const CellBlock = ({
  error,
  rune,
  count,
  alreadyCollected,
  matched,
  previewRune,
  speed,
}: CellBlockProps) => {
  if (error) {
    return (
      <ErrorCellBlock speed={speed}>
        <span className="rune">{rune}</span>
        {typeof count === "number" && <span className="counter">{count}</span>}
      </ErrorCellBlock>
    );
  }
  if (alreadyCollected) {
    return (
      <BlockedBlock speed={speed}>
        <span className="rune">{rune}</span>
        {typeof count === "number" && <span className="counter">{count}</span>}
      </BlockedBlock>
    );
  }
  if (matched) {
    return (
      <MatchBlock speed={speed}>
        <span className="rune">{rune}</span>
      </MatchBlock>
    );
  }
  return (
    <CellBlockEl speed={speed}>
      {previewRune ? (
        <span className="rune preview">{previewRune}</span>
      ) : (
        <span className="rune">{rune}</span>
      )}
      {typeof count === "number" && <span className="counter">{count}</span>}
    </CellBlockEl>
  );
};

type TileProps = {
  cell: CellType;
  gameSize: 3 | 4 | 5;
  alreadyCollected?: boolean;
  matchResult?: MatchResult;
};

export const Cell = ({
  alreadyCollected = false,
  cell: { solution },
  matchResult,
}: TileProps) => {
  const matched = matchResult?.success;
  const [displayBlocks] = useState<string>(
    (matched ? matchResult.word : solution?.word) || ""
  );

  return (
    <div className={`cell${alreadyCollected ? " collected" : ""}`}>
      {Array.from(displayBlocks || []).map((r, i) => (
        <CellBlock
          alreadyCollected={alreadyCollected}
          key={`${displayBlocks}-${r}-${i}`}
          rune={r}
          matched={matched}
        />
      ))}
    </div>
  );
};
