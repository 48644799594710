import styled from "styled-components";
import { FlickerIn, NeonGlow } from "../../styles/effects";
import { configDefaults as config } from "./config";

export const Title = styled.h1`
  font-family: "Share Tech Mono", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: rgb(0, 0, 0);
  ${NeonGlow("rgba(124,39,108,0.78)", 0.8, true, false)};
  font-size: 8vh;
  margin: 0;
  white-space: nowrap;
`;

export const MachineTitle = styled.h1`
  font-family: "Share Tech Mono", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: rgb(0, 0, 0);
  ${NeonGlow("rgba(94,26,81,0.71)", 0.5, true, false)};
  font-size: 5.5vw;
  text-align: center;
  @media (min-aspect-ratio: 4/5) {
    font-size: 3vh;
  }
`;

export const Splash = styled.div`
  font-family: "Gabarito", sans-serif;
  font-weight: 700;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  h2 {
    font-size: 12vh;
    color: #bd5cb5;
    font-weight: 400;
    margin: 0;
  }

  h3 {
    font-size: 3vh;
    color: #aaa;
    margin: 0;
  }

  button {
    font-family: "Gabarito", sans-serif;
    cursor: pointer;
    width: 15vh;
    height: 15vh;
    margin: 3vw;
    border-radius: 50%;
    border: 1px solid rgb(101, 24, 95);
    background: rgba(105, 46, 131, 0.02);
    color: #954698;
    font-size: 7vh;

    &.continue {
      width: 20vh;
      height: 20vh;
      font-size: 4vh;
      color: #c46dc3;
    }

    &.reload {
      border-radius: 1vh;
      width: 20vh;
      height: 10vh;
      font-size: 3vh;
    }

    &:hover {
      color: #f29afc;
      background: rgba(112, 42, 141, 0.07);
      backdrop-filter: blur(0.5vh);
      ${NeonGlow("rgba(93,43,134,0.13)", 1, false)}
    }
  }
`;

export const GameContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  transform: scale(0.95);
  .stars {
    opacity: 1;
  }
  transition: filter ${config.PHASE_TIMING.FAIL}ms;
  &.HUD-MISMATCH,
  &.HUD-FAIL {
    ${NeonGlow("rgba(91,10,32, 1)", 2, true)}
    background-color: rgba(114, 4, 30, 0.09);
    color: #5e1221;
    .cell-container,
    .stars {
      filter: blur(1px);
    }
    .stars {
      opacity: 0;
      transition: opacity ${config.PHASE_TIMING.FAIL}ms;
    }
  }

  &.HUD-SEARCHING {
    .stars {
      opacity: 1;
      transition: opacity 2ms;
    }
  }
`;

export const PauseButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: "Gabarito", sans-serif;
  cursor: pointer;
  width: 7vh;
  height: 7vh;
  margin: 3vw;
  border-radius: 50%;
  border: 1px solid rgb(118, 34, 131);
  background: rgba(105, 46, 131, 0.02);
  color: rgb(175, 75, 178);
  opacity: 0.8;
  font-size: 2vh;

  &:hover {
    opacity: 1;
    color: rgb(243, 124, 241);
    border: 1px solid rgb(128, 52, 131);
    background: rgba(112, 42, 141, 0.07);
    backdrop-filter: blur(0.5vh);
    ${NeonGlow("rgba(93,43,134,0.13)", 1, false)}
  }
`;
export const Grid = styled.div`
  display: grid;
  height: calc(100vh);
  width: calc(100vw);
  position: relative;
  color: #d3cbcb;
  font-family: "Gabarito", sans-serif;
  font-size: 4vh;
  grid-template-columns: 1.3fr 1fr 1.3fr;
  grid-template-rows: 1.3fr 1fr 1.3fr;
  position: relative;
  top: -3vh;
  & > div {
    position: relative;
    z-index: 5;
  }

  .wrapper-top {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
  }

  .matcher {
    color: red;
  }
`;

export const Left = styled.div`
  padding: 1vh;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: flex-end;
  position: relative;
  left: 5vh;
  cursor: pointer;
  .cell,
  .tile {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: flex-end;
  }
`;

export const Top = styled.div`
  padding: 1vh;
  position: relative;
  cursor: pointer;
  top: 5vh;
  .cell {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const Right = styled.div`
  padding: 1vh;
  display: flex;
  flex-direction: row;
  align-self: center;
  cursor: pointer;
  justify-content: flex-start;
  position: relative;
  left: -5vh;
  .cell,
  .tile {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: flex-end;
  }
`;

export const Bottom = styled.div`
  padding: 1vh;
  position: relative;
  cursor: pointer;
  top: -5vh;
  .cell {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Empty = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  font-size: 2vh;
  color: #9f75ef;
`;

export const Score = styled.div`
  padding: 3vh;
  font-size: 4vh;
  color: #ad9bd0;
  font-weight: 400;

  p {
    margin: 0;
  }

  p.completions {
    color: #943192;
    font-size: 2.2vh;
  }
`;

export const Life = styled.div`
  padding: 3vh;
  font-size: 4vh;
  text-align: right;
  color: #ad9bd0;
  font-weight: 400;

  .heart {
    font-size: 4vh;
    color: #8d3194;
  }

  p {
    margin: 0;
  }

  p.level {
    color: #a25cad;
    font-size: 2.2vh;
  }

  &.low {
    color: rgb(101, 11, 27);

    .heart {
      color: rgb(75, 6, 20);
    }
  }
`;

export const CellWrapper = styled.div`
  div {
    opacity: 1;
  }
  .already-collected {
    animation: ${FlickerIn} 1s linear;
  }
`;
