import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Machine } from "../_shared/Machine";
import {
  alphaSort,
  drawTiles,
  findAllWords,
  generateRack,
  getWordsByLength,
  sendEvent,
} from "../_shared/utils";
import {
  Button,
  GameContainer,
  GoalLine,
  Splash,
  Title,
  Tower,
  TowerWord,
} from "./elements";

const words = getWordsByLength(3);
export const getGarbageDist = (rack: string) => {
  const realGarbage = "ZXQJKVVVGWW".split("");
  let possible: string[] = rack.split("");
  realGarbage.forEach((t) => {
    if (!rack.split("").includes(t)) {
      possible.push(t);
    }
  });
  return possible.join("");
};
export const Triad = () => {
  const [rack, setRack] = useState<Array<string>>([]);
  const [gameStarted, setGameStarted] = useState<boolean>(false);
  const [displayRack, setDisplayRack] = useState<Array<string>>([]);
  const [initialized, setInitialized] = useState(false);
  const [paused, setPaused] = useState(false);
  const [attempt, setAttempt] = useState("");
  const [hint, setHint] = useState("");
  const [hintsUsed, setHintsUsed] = useState(0);
  const [errors, setErrors] = useState<number>(0);
  const [score, setScore] = useState<number>(0);
  const celebrateTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [celebrating, setCelebrating] = useState(false);
  const [wordsFound, setWordsFound] = useState<Set<string>>(new Set());
  const [wordsFoundRound, setWordsFoundRound] = useState<Set<string>>(
    new Set()
  );
  const [goalWords, setGoalWords] = useState<Array<string>>([]);
  const [wordsRemainingGame, setWordsRemainingGame] = useState(words.length);
  const [badRacks, setBadRacks] = useState<Set<string>>(new Set());

  const analyticsData = useMemo(
    () => ({
      game: "tower",
      score,
      wordsFound: wordsFound.size,
    }),
    [score, wordsFound]
  );
  useEffect(() => {
    document.title =
      "StudyCade | Tower of Threes! Learn short words on StudyCade";
    sendEvent("page_view", {
      page_title: "tower",
      page_location: "/#/tower",
    });
    sendEvent("machine_load", { game: "tower" });
  }, []);
  const addToAttempt = useCallback(
    (letter: string) => {
      const newRack = displayRack.slice();
      const index = newRack.indexOf(letter);
      if (index > -1 && attempt.length < 3) {
        newRack.splice(index, 1, "");
        setDisplayRack(newRack);
        setAttempt((x) => x + letter);
      }
    },
    [displayRack, attempt]
  );

  const removeFromAttempt = useCallback(() => {
    const newRack = displayRack.slice();
    const letterToReturn = attempt[attempt.length - 1];
    setAttempt((x) => x.substring(0, x.length - 1));
    const index = rack.findIndex((l, i) => l === letterToReturn && !newRack[i]);
    if (index > -1) {
      newRack[index] = letterToReturn;
      setDisplayRack(newRack);
    }
  }, [attempt, displayRack, rack]);

  const restoreRack = useCallback(() => {
    setHint("");
    setDisplayRack(rack);
    setAttempt("");
  }, [rack]);
  useEffect(() => {
    if (errors === 3) {
      restoreRack();
      setErrors(0);
      setWordsFoundRound(new Set());
    }
  }, [errors, rack, attempt, restoreRack]);

  const wordsToFind = useMemo(() => {
    return goalWords.filter((w) => !wordsFoundRound.has(w));
  }, [goalWords, wordsFoundRound]);

  useEffect(() => {
    if (attempt.length) {
      setHint("");
    }
    if (attempt.length === 3) {
      console.warn("correct");
      if (!wordsFoundRound.has(attempt)) {
        if (words.includes(attempt)) {
          setWordsFoundRound((x) => new Set([...x, attempt]));
          setErrors(0);
          if (wordsToFind.length !== 1) {
            restoreRack();
          }
        } else {
          console.warn("no");
          setErrors((x) => x + 1);
          restoreRack();
        }
      }
    }
  }, [attempt, restoreRack, wordsFoundRound, wordsFound, wordsToFind]);

  useEffect(() => {
    setWordsRemainingGame(
      words.length - new Set([...wordsFound, ...wordsFoundRound]).size
    );
  }, [wordsFound, wordsFoundRound]);
  const addHint = useCallback(() => {
    setHint((hint) => (attempt.length === 0 ? wordsToFind[0]?.[0] : ""));
    setHintsUsed((x) => x + 1);
  }, [attempt, wordsToFind]);
  useEffect(() => {
    if (!rack.length && (!initialized || wordsRemainingGame > 0)) {
      if (!words.filter((w) => !wordsFound.has(w)).length) {
        setWordsRemainingGame(0);
        return;
      }
      let newRack = generateRack(
        words.filter((w) => !wordsFound.has(w)),
        5,
        10,
        words
      ).split("");
      if (newRack.length < 7) {
        let tempRack = newRack.concat(
          drawTiles(7 - newRack.length, getGarbageDist(newRack.join("")))
        );
        let attempts = 0;
        let giveUpNow = 0;
        //ick
        while (findAllWords(tempRack, words).length > 25) {
          attempts++;
          giveUpNow++;
          tempRack = newRack.concat(
            drawTiles(7 - newRack.length, getGarbageDist(newRack.join("")))
          );
          if (attempts > 20) {
            newRack = generateRack(
              words.filter((w) => !wordsFound.has(w)),
              7,
              20
            ).split("");
            attempts = 0;
          }
          if (giveUpNow > 100) {
            newRack = generateRack(
              words.filter((w) => !wordsFound.has(w)),
              7,
              20
            ).split("");
            break;
          }
        }
      }
      setGoalWords(findAllWords(newRack, words));
      if (wordsRemainingGame) {
        setWordsFoundRound(new Set());
        setRack(newRack.sort(alphaSort));
        setDisplayRack(newRack.sort(alphaSort));
        setInitialized(true);
      }
    }
  }, [initialized, rack, wordsFound, wordsFoundRound, wordsRemainingGame]);

  const handleInput = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      const { key } = e;
      const k = key.toUpperCase();
      if (
        (!rack.length && !wordsToFind?.length) ||
        celebrating ||
        !wordsRemainingGame ||
        !initialized
      ) {
        return;
      }
      if (rack.includes(k) && attempt.length < 3) {
        addToAttempt(k);
      }
      if (key === "Backspace" && attempt.length > 0) {
        removeFromAttempt();
      }
      if (key === " " || key === "Escape") {
        addHint();
      }
    },
    [
      addHint,
      addToAttempt,
      attempt,
      celebrating,
      initialized,
      rack,
      removeFromAttempt,
      wordsToFind,
      wordsRemainingGame,
    ]
  );

  useEffect(() => {
    document.addEventListener("keyup", handleInput);
    return () => {
      document.removeEventListener("keyup", handleInput);
    };
  }, [handleInput]);

  useEffect(() => {
    if (!wordsRemainingGame) {
      return;
    }
    if (
      wordsFoundRound?.size === goalWords?.length &&
      wordsFoundRound?.size > 0 &&
      initialized
    ) {
      setCelebrating(true);
      celebrateTimer.current = setTimeout(() => {
        setScore((x) => x + 100);
        restoreRack();
        setWordsFound((x) => new Set([...x, ...wordsFoundRound]));
        setWordsFoundRound(new Set());
        setRack([]);
        setDisplayRack([]);
        setCelebrating(false);
      }, 3000);
    }
  }, [
    goalWords,
    initialized,
    rack,
    attempt,
    restoreRack,
    wordsToFind,
    wordsFoundRound,
    wordsRemainingGame,
  ]);

  const renderAttempt = useMemo(
    () =>
      attempt
        .toUpperCase()
        .split("")
        .map((t, i) => (
          <span
            key={`${t}-${i}`}
            onClick={() => {
              removeFromAttempt();
            }}
          >
            {t}
          </span>
        )),
    [attempt, removeFromAttempt]
  );

  const tower = useMemo(
    () => (
      <Tower>
        {[...wordsFoundRound].sort().map((w, i) => (
          <React.Fragment key={w}>
            <TowerWord
              count={wordsFoundRound.size}
              index={i}
              key={w}
              data-index={i + 1}
              data-count={wordsFoundRound.size}
              errors={errors}
            >
              {w}
            </TowerWord>
          </React.Fragment>
        ))}
        {!celebrating &&
          goalWords.length !== wordsFoundRound.size &&
          goalWords.map((g, i) => (
            <GoalLine
              errors={errors}
              index={i}
              key={`goal-line-${i}`}
              last={i === goalWords.length - 1}
            >
              &nbsp;
            </GoalLine>
          ))}
      </Tower>
    ),
    [celebrating, errors, wordsFoundRound, goalWords]
  );

  const serializeState = useCallback(() => {
    localStorage.setItem(
      "tower",
      JSON.stringify({
        hintsUsed,
        rack,
        score,
        wordsFound: [...wordsFound],
        wordsFoundRound: [...wordsFoundRound],
      })
    );
  }, [hintsUsed, rack, score, wordsFound, wordsFoundRound]);

  return (
    <Machine
      title={
        <Title>
          <h3>
            <span className="tower">Tower</span>
            <span className="of">of</span>
            <span className="threes">Threes</span>
          </h3>
        </Title>
      }
      description="Study the threes. Build the tower to the goal line with all the valid 3 letter words available."
    >
      <GameContainer
        className={`container ${celebrating ? "celebrating" : ""}`}
      >
        {!paused && gameStarted && (
          <>
            {tower}
            {!celebrating && <div className="attempt">{renderAttempt}</div>}
            <div className="rack">
              {rack.map((t, i) => (
                <span
                  key={`${t}-${i}`}
                  onClick={() => {
                    addToAttempt(t);
                  }}
                >
                  {t}
                </span>
              ))}
            </div>
            <div className="display-rack">
              {displayRack.map((t, i) => (
                <span
                  key={`${t}-${i}`}
                  onClick={() => {
                    addToAttempt(t);
                  }}
                >
                  {t ? t : " "}
                </span>
              ))}
            </div>
            <div className="hint">
              {hint.split("").map((t, i) => (
                <span
                  key={`${t}-${i}`}
                  onClick={() => {
                    addToAttempt(t);
                    sendEvent("get_hint", analyticsData);
                  }}
                >
                  {t}
                </span>
              ))}
            </div>
            <div className="score">{score}</div>
            <div
              className="save"
              onClick={() => {
                serializeState();
                setPaused(true);
                sendEvent("pause_game", analyticsData);
              }}
            >
              <i className="fa-solid fa-pause" />
            </div>
            <div className="hints-used">Hints used: {hintsUsed}</div>
            <div className="seen">
              {words.length - wordsRemainingGame}/{words.length}
            </div>
            {attempt.length === 0 && (
              <button className="add-hint" onClick={addHint}>
                ?
              </button>
            )}
          </>
        )}
        {!gameStarted && (
          <Splash>
            <h3>
              <span className="tower">Tower</span>
              <span className="of">of</span>
              <span className="threes">Threes</span>
            </h3>
            <h4>
              <span className="tower">Tower</span>
              <span className="of">of</span>
              <span className="threes">Threes</span>
            </h4>
            <Button
              onClick={() => {
                setGameStarted(true);
                sendEvent("start_game", analyticsData);
              }}
            >
              New Game
            </Button>
            {!!localStorage.getItem("tower") && (
              <Button
                onClick={() => {
                  const gameState = JSON.parse(
                    localStorage.getItem("tower") || "{}"
                  );
                  setWordsFound(new Set([...gameState.wordsFound]));
                  setWordsFoundRound(new Set([...gameState.wordsFoundRound]));
                  setRack(gameState.rack);
                  setDisplayRack(gameState.rack);
                  setHintsUsed(gameState.hintsUsed);
                  setScore(gameState.score);
                  setGoalWords(findAllWords(gameState.rack, words));
                  setGameStarted(true);
                  sendEvent("load_game", {
                    ...analyticsData,
                    wordsFound: gameState.wordsFound.length,
                  });
                }}
              >
                Load saved
              </Button>
            )}
          </Splash>
        )}
        {paused && (
          <Splash>
            <h3>
              <span className="tower">Tower</span>
              <span className="of">of</span>
              <span className="threes">Threes</span>
            </h3>
            <h4>
              <span className="tower">Tower</span>
              <span className="of">of</span>
              <span className="threes">Threes</span>
            </h4>
            <Button
              onClick={() => {
                setPaused(false);
                sendEvent("unpause_game", analyticsData);
              }}
            >
              Continue
            </Button>
            <Button
              onClick={() => {
                setWordsFound(new Set());
                setWordsFoundRound(new Set());
                setRack([]);
                setDisplayRack([]);
                setHintsUsed(0);
                setPaused(false);
                sendEvent("start_game", analyticsData);
              }}
            >
              New Game
            </Button>
          </Splash>
        )}
      </GameContainer>
    </Machine>
  );
};
