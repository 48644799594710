import React from "react";
import back0 from "./1.jpg";
import back1 from "./2.jpg";
import back2 from "./3.jpg";
import back3 from "./4.jpg";
import back4 from "./5.jpg";
import back5 from "./6.jpg";
import styled from "styled-components";
import { shuffle } from "lodash";

const backgrounds = shuffle([back0, back1, back2, back3, back4, back5]);

const Img = styled.img`
  position: relative;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  &.over {
    opacity: 0.4;
  }
`;

const FlippedImg = styled.img`
  position: relative;
  &.over {
    opacity: 0.4;
  }
`;
const Vignette = styled.div`
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 0 2vw rgba(0, 0, 0, 1), inset 0 0 5vw 2vw black,
    inset 0 0 30vw 1vw #000000, 40vw 19vw rgb(37 0 27 / 23%),
    inset 0 0 20vw 5vw #28081f55;
`;

type BackgroundProps = {
  variation: number;
  flip: boolean;
  over?: boolean;
};
export const Background = ({ variation, flip, over }: BackgroundProps) => {
  return (
    <>
      {flip ? (
        <FlippedImg
          className={over ? "over" : ""}
          src={backgrounds[variation || 1]}
        />
      ) : (
        <Img className={over ? "over" : ""} src={backgrounds[variation || 1]} />
      )}
      <Vignette />
    </>
  );
};
