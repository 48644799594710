import React from "react";
import styled from "styled-components";
import { Logo } from "../styles/nav/logo";
import * as SC from "../styles/sharedStyles";

const SplashContainer = styled.div`
  width: 100vw;
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  button {
    width: fit-content;
    font-size: 2vh;
    margin-bottom: 2vh;
    cursor: pointer;
  }
`;

const Footer = styled.footer`
  font-size: 1.5vh;

  position: absolute;
  bottom: 3vh;
  p {
    margin: 0.5vh;
    opacity: 0.7;
  }
  a {
    color: white;
    margin: 0.5vh;
    font-weight: 800;
    font-family: Montserrat;
    opacity: 0.7;
    text-decoration: none;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.44);
    &:hover {
      opacity: 1;
    }
  }
`;

const SplashLogo = styled(Logo)`
  margin-bottom: 6vh;
  font-size: 5vh;
  margin-top: 30vh;
`;

export const Splash = () => (
  <SplashContainer>
    <SplashLogo />
    <SC.ButtonSecondary>
      <SC.LinkPlain to="/letsroll">Let's Roll</SC.LinkPlain>
    </SC.ButtonSecondary>
    <SC.Button>
      <SC.LinkPlain to="/triad">Tower of Threes</SC.LinkPlain>
    </SC.Button>
    <SC.ButtonSecondary>
      <SC.LinkPlain to="/hyperspace">Hyperspace Hooks</SC.LinkPlain>
    </SC.ButtonSecondary>
    <Footer>
      <p>© 2022-{new Date().getFullYear()} Lola McKissen </p>
      <a
        href="https://www.patreon.com/Studycade"
        target="_blank"
        rel="noreferrer"
      >
        Support StudyCade!
      </a>
    </Footer>
  </SplashContainer>
);
