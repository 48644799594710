import React, { useMemo, useState } from "react";
import { Incoming } from "./games/hyperspace/components/Incoming";
import styled, { ThemeProvider } from "styled-components";
import { neonOnBrick as theme } from "./themes/neonOnBrick";
import * as SC from "./styles/sharedStyles";
import Stars from "./games/hyperspace/components/Stars";

const Container = styled.div`
  min-height: 50vh;
  min-width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .tile.DESTROYED {
    display: block;
    background-color: #ccc;
  }
`;

export const Playground = () => {
  const [lastModified, setLastModified] = useState<number>(Date.now());
  const rune = useMemo(() => lastModified.toString().slice(-1), [lastModified]);
  return (
    <ThemeProvider theme={theme}>
      <SC.GlobalStyles />
      <Container
        onClick={() => {
          setLastModified(Date.now());
        }}
      >
        {}
      </Container>
    </ThemeProvider>
  );
};
