import React, { ReactElement } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { Splash } from "./splash/Splash";
import { ThemeProvider } from "styled-components";
import { neonOnBrick as theme, neonOnBrickNav } from "./themes/neonOnBrick";
import * as SC from "./styles/sharedStyles";
//import the games conditionally to help Webpack out
import { Hyperspace } from "./games/hyperspace/hyperspace";
import { Nav } from "./styles/nav/Nav";
import { Playground } from "./Playground";
import { Triad } from "./games/triad/triad";
import { LetsRoll } from "./games/letsroll/LetsRoll";
import { Fourtress } from "./games/fourtress/fourtress";

const getLayout = (el: ReactElement, nav?: boolean) => (
  <ThemeProvider theme={nav ? neonOnBrickNav : theme}>
    <SC.GlobalStyles />
    {nav ? <Nav>{el}</Nav> : el}
  </ThemeProvider>
);

export const Router = () => (
  <HashRouter>
    <Routes>
      <Route path="/" element={getLayout(<Splash />)} />

      <Route path="/hyperspace" element={getLayout(<Hyperspace />, true)} />
      <Route path="/tower" element={getLayout(<Triad />, true)} />
      <Route path="/triad" element={<Navigate to="/tower" />} />
      <Route path="/fourtress" element={getLayout(<Fourtress />, true)} />
      <Route path="/letsroll" element={getLayout(<LetsRoll />, true)} />
      <Route path="/sushi" element={<Navigate to="/letsroll" />} />
      <Route path="/playground" element={<Playground />} />
    </Routes>
  </HashRouter>
);
