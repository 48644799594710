import React, { ReactNode, useContext, useEffect, useRef } from "react";
import styled, {
  css,
  DefaultTheme,
  StyleSheetManager,
} from "styled-components";
import Frame, { FrameContext } from "react-frame-component";
import { CSSColor } from "../../styles/sharedStyles";
import { GlassEffect } from "../../styles/effects";

export interface GameTheme extends DefaultTheme {
  background: CSSColor;
}
type MachineProps = {
  children?: React.ReactNode;
  title?: React.ReactNode;
  description?: string;
};

const sharedStyles = css`
  width: 100vw;
  height: 100vw;
  margin: 0;
  position: relative;
  transform: translate(0%, 5vw);
  transform-origin: top;
  border-radius: 0;
  border: 0;
  padding: 0;

  @media (min-aspect-ratio: 3/5) {
    border: ${({ theme }) => theme.cabinet.border};
    width: calc(90vw - 64px);
    height: calc(90vw - 64px);
    border-radius: 10%;
    overflow: hidden;
    transform: translate(0%, 0%);
  }
  @media (min-aspect-ratio: 5/7) {
    width: calc(85vw - 64px);
    height: calc(85vw - 64px);
  }
  @media (min-aspect-ratio: 1/1) {
    width: calc(80vh - 64px);
    height: calc(80vh - 64px);
  }
  @media (min-aspect-ratio: 5/3) {
    position: relative;
    width: calc(110vh - 24vh);
    height: calc(110vh - 24vh);
    top: 0vh;
    margin: 0;
    border-radius: 0;
    border: 0;
    padding: 0;
  }
`;

const Overlay = styled.div`
  ${() => sharedStyles}
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  box-shadow: inset 0 0 10vw rgba(0, 0, 0, 0.1), inset 0 0 8vw black,
    inset 0 0 3vw black;
  mix-blend-mode: soft-light;
  opacity: 0.4;
`;

const DisplayContainer = styled.div`
  position: relative;
  touch-action: manipulation;
  iframe {
    overflow: hidden;
  }
  html {
    overflow: hidden;
  }
  body {
    margin: 0;
    overflow: hidden;
  }
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .frame-root {
    overflow: hidden;
  }
`;

const Display = styled(Frame)`
  background-color: rgba(3, 3, 3, 0.5);
  width: 100%;
  height: 100%;
  padding: 0;
  ${() => sharedStyles}
  @media(max-aspect-ratio: 3 / 5) {
    background-color: rgba(3, 3, 3, 0.5);
  }
`;

const Description = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 1.3vh;
  margin: 0 auto;
  text-align: center;
  padding: 0.5vh 2vh 1vh;
  opacity: 0.8;
  @media (min-aspect-ratio: 4/5) {
    text-align: left;
    padding: 1vh 2vh 1vh 1.5vh;
  }
`;

const TitlePlate = styled.div`
  ${GlassEffect("rgba(28,27,28,0.5)", 0.6)};
  border-radius: 1vw;
  position: relative;
  max-width: 70vw;
  border: 1px solid #232222;
  margin: 3vw auto;
  padding: 3vw;
  align-items: center;
  div {
    text-align: center;
    width: 100%;
  }
  .title-container {
    margin: 1vh 0;
  }
  @media (min-aspect-ratio: 4/5) {
    display: flex;
    padding: 1vh;
    .title-container {
      margin: 0;
    }
  }
  @media (min-aspect-ratio: 1/1) {
    max-width: 70vh;
    top: -2vw;
  }
`;

type StyledFrameProps = {
  children?: ReactNode;
};

export const StyledFrame = ({ children }: StyledFrameProps) => {
  return (
    <Frame
      initialContent={
        '<!DOCTYPE html><html style="overflow: hidden"><head><link rel="preconnect" href="https://fonts.googleapis.com">\n' +
        '    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>\n' +
        '    <link rel="preconnect" href="https://fonts.googleapis.com">' +
        '<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>' +
        '<link rel="preconnect" href="https://fonts.googleapis.com">\n' +
        '<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>\n' +
        '<link rel="preconnect" href="https://fonts.googleapis.com">\n' +
        '<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>\n' +
        '<link href="https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;800;900&family=MedievalSharp&family=Montserrat:ital,wght@0,200;0,400;0,500;0,700;0,900;1,100;1,300;1,400&family=Play:wght@400;700&family=Roboto+Condensed:wght@100;400;900&family=Roboto:wght@300;400&family=Saira+Extra+Condensed:wght@100;400;800;900&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet">' +
        '<meta name="viewport" content="width=device-width, initial-scale=1 " />\n' +
        '        <link href="/fontawesome/css/fontawesome.css" rel="stylesheet">\n' +
        '    <link href="/fontawesome/css/brands.css" rel="stylesheet">\n' +
        '    <link href="/fontawesome/css/solid.css" rel="stylesheet"></head><body style="overflow: hidden;  margin: 0;"><div class="frame-root"></div><div id="modal-root"></div></body></html>'
      }
      id="game"
      tabIndex={-1}
      seamless
      onClick={() => {
        (document.activeElement as HTMLElement).blur();
      }}
      onFocus={() => {
        (document.activeElement as HTMLElement).blur();
      }}
      style={{
        display: "block",
        border: "0",
        width: "calc(100vw - 16px)",
        height: "calc(100vw - 16px)",
        margin: "0",
        overflow: "hidden",
        pointerEvents: "visible",
      }}
    >
      <InjectFrameStyles>{children}</InjectFrameStyles>
    </Frame>
  );
};

const InjectFrameStyles = ({ children }: MachineProps) => {
  const { document } = useContext(FrameContext);
  return (
    <StyleSheetManager target={document!.head}>{children}</StyleSheetManager>
  );
};

export const Machine = ({ children, title, description }: MachineProps) => {
  const machine = useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.addEventListener("keyup", (e) => {
      const frame = machine.current?.getElementsByTagName("iframe");
      if (frame?.length) {
        // dispatch a new event
        frame[0]!.contentDocument?.dispatchEvent(
          new KeyboardEvent("keyup", { key: e.key })
        );
      }
    });
  }, []);
  return (
    <div ref={machine} style={{ position: "relative" }}>
      <DisplayContainer>
        <Display>
          <StyledFrame>{children as React.ReactNode}</StyledFrame>
        </Display>
        <Overlay />
      </DisplayContainer>
      {!!title && !!description && (
        <TitlePlate>
          <div className="title-container">{title}</div>
          <Description>{description}</Description>
        </TitlePlate>
      )}
    </div>
  );
};
