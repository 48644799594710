import React, { useEffect, useMemo, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Config, configDefaults } from "../config";
import { MatchResult } from "../hyperspace";
import { signedRandom } from "../../_shared/utils";

export const Collide = keyframes`
  0% {
    transform: rotate(0)  translate(-2vh, 2vh) scale(1);
  }
  4% {
    transform: rotate(180deg)  translate(2vh, 2vh) scale(1);
  }
   21%{
    transform: rotate(-90deg) translate(0, 2vh) scale(0.5);
  }
   39% {
    transform: rotate(-120deg) translate(1vh, -2vh ) scale(0.3);
  }
  70% {
    transform: rotate(-120deg) translate(-1vh, 2vh ) scale(0.1);
  }
  100% {
    transform: rotate(272deg) translate3d(0, -1vh, 0) scale(0) ;
  }
`;

const Focus = keyframes`
  0% { filter: blur(5vh);}
  100% {filter: blur(0vh);}
`;

const zoom = () => keyframes`
  0% {
    transform: scale(0.1);
  }
  25% {
    transform: scale(0.6)
  }
  
  100% {
    transform: scale(1)  ;
  }
`;

const zoomSmall = () => keyframes`
  0% {
    transform: scale(0.2);
  }

  25% {
    transform: scale(0.45);
  }
  50% {
    transform: scale(0.7) ;
  }
  
  100% {
    transform: scale(1)  ;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(0, 0) rotate(0);
  color: white;
  pointer-events: none;

  .lock {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    padding: 2vh;
    width: 100%;
    height: 100%;
    filter: blur(0vh);
    animation: ${Focus} 1ms ease-in forwards;
  }

  .tile {
    background: #2d2931;
    color: #f3f3f3;
    font-weight: 700;
    border-radius: 1vw;
    width: 18vw;
    height: 18vw;
    opacity: 1;
    font-family: "Gabarito", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;

    .rune {
      font-size: 13vh;
      line-height: 1.15em;
      width: 100%;
      height: 100%;
      transform: translateY(1vh);
      text-align: center;
    }

    &.warn {
      border: 3px solid #5e1221;
    }
  }

  .tile.SEARCHING {
    visibility: hidden;
    transform: rotate(0) translate(0, 0);
  }

  .tile.NORMAL {
    transform: rotate(0) translate(0, 0);
  }

  .tile.FAIL,
  .tile.MISMATCH {
    // background: #311b20;
    animation: ${Collide} 1s linear;
    .lock {
      filter: blur(0);
    }
  }

  .tile.MATCH {
    transform: rotate(0) translate(0, 0);
    transition: all 1000ms cubic-bezier(0.02, 0.65, 0.71, 0.84);
  }

  .tile.COMPLETE {
    visibility: hidden;
    transform: rotate(0) translate(0, 0);
  }

  .tile.DESTROYED {
    visibility: hidden;
  }

  &.NORMAL .zoomer {
    animation: ${zoomSmall} 1s linear forwards;
    @media (min-height: 500px) and (min-width: 500px) {
      animation: ${zoom} 1s linear forwards;
    }
  }

  &.MATCH .zoomer {
    transform: scale(0);
    transition: all ${configDefaults.PHASE_TIMING.MATCH}ms;
  }
`;

type IncomingProps = {
  config: Config;
  handleStateChange: (tilePhase: TilePhase) => void;
  speed: number;
  rune: string;
  matchResult?: MatchResult;
  deflect?: boolean;
};

export enum TilePhase {
  Searching = "SEARCHING",
  Normal = "NORMAL",
  Fail = "FAIL",
  Match = "MATCH",
  Mismatch = "MISMATCH",
  Destroyed = "DESTROYED",
  Complete = "COMPLETE",
}

export const getTimingByPhaseAndSpeed = (
  tilePhase: TilePhase,
  speed: number,
  config: Config = configDefaults
) => {
  switch (tilePhase) {
    case TilePhase.Normal: {
      return speed * config.PHASE_TIMING.NORMAL;
    }
    case TilePhase.Searching: {
      return speed * config.PHASE_TIMING.SEARCHING;
    }
    case TilePhase.Match: {
      return speed * config.PHASE_TIMING.MATCH;
    }
    case TilePhase.Mismatch: {
      return speed * config.PHASE_TIMING.MISMATCH;
    }
    case TilePhase.Fail: {
      return config.PHASE_TIMING.FAIL;
    }
    default:
      return 0;
  }
};

export const getLockDelay = (speed: number = 1) =>
  speed * configDefaults.LOCK_DELAY * configDefaults.PHASE_TIMING.NORMAL;

export const Incoming = ({
  config,
  handleStateChange,
  speed = configDefaults.INITIAL_SPEED,
  matchResult,
  rune,
}: IncomingProps) => {
  const timing = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [tilePhase, setTilePhase] = useState<TilePhase | null>(null);
  const [offsetX, setOffsetX] = useState<number>();
  const [offsetY, setOffsetY] = useState<number>();
  const [rotate, setRotate] = useState<number>();
  const warnTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [tooClose, setTooClose] = useState(false);
  /* Initialize  */
  useEffect(() => {
    const x = signedRandom(speed / 3);
    const y = signedRandom(speed / 3);
    const r = signedRandom(speed);
    setTilePhase(TilePhase.Searching);
    setOffsetX(x);
    setOffsetY(y);
    setRotate(r);
  }, [speed]);

  /* When the phase changes, set a timer to advance to the next */
  useEffect(() => {
    if (tilePhase) {
      handleStateChange(tilePhase);
      timing.current = setTimeout(() => {
        switch (tilePhase) {
          case TilePhase.Searching: {
            setTilePhase(TilePhase.Normal);
            warnTimer.current = setTimeout(() => {
              setTooClose(true);
            }, getTimingByPhaseAndSpeed(TilePhase.Normal, speed, config) - config.WARN_TIMING);
            break;
          }
          case TilePhase.Normal: {
            setTilePhase(TilePhase.Fail);
            setTooClose(false);
            break;
          }
          case TilePhase.Match: {
            setTilePhase(TilePhase.Complete);
            break;
          }
          case TilePhase.Mismatch: {
            setTilePhase(TilePhase.Complete);
            break;
          }
          case TilePhase.Fail: {
            setTilePhase(TilePhase.Destroyed);
            break;
          }
          default:
            break;
        }
      }, getTimingByPhaseAndSpeed(tilePhase, speed, config));
    }
  }, [config, tilePhase, speed, handleStateChange]);

  // state change logger
  useEffect(() => {
    console.log(tilePhase);
  }, [tilePhase]);

  useEffect(() => {
    if (matchResult?.success) {
      setTilePhase(TilePhase.Match);
    } else if (matchResult) {
      setTilePhase(TilePhase.Mismatch);
    }
  }, [matchResult]);

  const positionStyles = useMemo(() => {
    switch (tilePhase) {
      case TilePhase.Searching:
        return {
          transform: `translate(${offsetX}vw, ${offsetY}vh) rotate(${rotate}deg)`,
        };
      case TilePhase.Normal:
        return {
          transitionDuration: `${speed * 0.2 * config.PHASE_TIMING.NORMAL}ms`,
          transitionTimingFunction: "ease-in-out",
        };
      case TilePhase.Fail:
        return {
          animationDuration: `${speed * config.PHASE_TIMING.FAIL}ms`,
        };
      case TilePhase.Mismatch:
        return {
          animationDuration: `${speed * config.PHASE_TIMING.MISMATCH}ms`,
        };
      default:
        return {};
    }
  }, [config, tilePhase, speed, offsetX, offsetY, rotate]);

  return (
    <>
      <Container className={`space ${tilePhase} ${matchResult?.position}`}>
        <div
          className="lock"
          style={{
            pointerEvents: "none",
            animationDuration: `${getLockDelay(speed)}ms`,
          }}
        >
          <div
            className="zoomer"
            style={{
              animationDuration: `${speed * config.PHASE_TIMING.NORMAL}ms`,
            }}
          >
            <div
              className={`tile ${tilePhase} ${tooClose ? "warn" : ""}`}
              style={positionStyles}
            >
              <div className="rune" data-testid="rune">
                {rune}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
