import React from "react";
import styled from "styled-components";

const LogoElement = styled.h1`
  color: ${({ theme }) => theme.text};
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-weight: 400;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 0.2em;
  ${({ theme }) => theme.logoOverrides}
`;

type LogoProps = {
  className?: string;
};

const LogoStudy = styled.span``;
const LogoCade = styled.span``;

export const Logo = ({ className }: LogoProps) => (
  <LogoElement className={className}>
    <LogoStudy>Study</LogoStudy>
    <LogoCade>Cade</LogoCade>
  </LogoElement>
);
