import styled, { keyframes } from "styled-components";
import { BrokenEffect, GlassEffect, NeonGlow } from "../../styles/effects";
import {
  darken,
  desaturate,
  lighten,
  saturate,
  transparentize,
} from "polished";
import { signedRandom } from "../_shared/utils";
import { sc } from "../../styles/sharedStyles";

export const GameContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  font-family: "Gabarito", sans-serif;
  height: 100vh;
  justify-content: center;
  align-items: center;
  width: 100vh;
  touch-action: manipulation;

  .rack,
  .display-rack {
    bottom: 2vh;
    webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    cursor: pointer;
    font-size: 8vh;
    font-weight: 400;
    letter-spacing: 6vh;
    line-height: 6vh;
    min-height: 8vh;
    position: absolute;

    span {
      display: inline-block;
      min-width: 13vh;
      flex-basis: 13vh;
      text-align: center;
    }
  }

  .display-rack {
    pointer-events: none;
  }

  .rack {
    opacity: 0.5;
  }

  .attempt {
    bottom: 14vh;
    font-size: 6vh;
    font-weight: 700;
    letter-spacing: 2vh;
    line-height: 6vh;
    min-height: 6vh;
    position: absolute;
  }

  .hint {
    bottom: 14vh;
    font-size: 6vh;
    font-weight: 700;
    letter-spacing: 2vh;
    line-height: 6vh;
    min-height: 6vh;
    position: absolute;
    opacity: 0.4;
  }

  input {
    background-color: black;
  }

  .score {
    top: 2vh;
    font-size: 3vh;
    position: absolute;
    left: 5vh;
  }

  .hints-used {
    color: rgb(208, 124, 218);
    position: absolute;
    font-size: 2.5vh;
    font-weight: 00;
    top: 2vh;
    right: 10vh;
    opacity: 0.7;
  }

  .seen {
    color: rgb(208, 124, 218);
    position: absolute;
    font-size: 2.5vh;
    bottom: 0.5vh;
    right: 5vh;
    opacity: 0.7;
  }

  .save {
    bottom: 0.5vh;
    color: rgb(208, 124, 218);
    cursor: pointer;
    font-size: 2.8vh;
    left: 5vh;
    opacity: 0.7;
    position: absolute;
  }

  .add-hint {
    background: black;
    color: rgb(203, 107, 144);
    cursor: pointer;
    display: flex;
    top: 2vh;
    font-size: 2.5vh;
    font-weight: bold;
    position: absolute;
    align-items: center;
    justify-content: space-around;
    right: 4vh;
    width: 3.2vh;
    height: 3.2vh;
    border-radius: 50%;
    border: 1px solid rgb(94, 27, 75);

    &:hover {
      ${NeonGlow("rgba(208,62,121,0.57)", 0.1, true, false)}
    }

    &.celebrating {
      border: 10vh white solid;

      .attempt {
        display: none;
      }
    }
  }
`;

export const Dropdown = keyframes`
  0% { transform:  rotateX(40deg) scaleX(30%) scaleY(10%) translateY(-350vh);}
  100% { transform: rotateX(40deg) scaleX(100%) scaleY(80%) translateY(18vh);}
`;

export const Splash = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2vh;
  height: 100vh;
  justify-content: center;
  perspective: 120vh;
  perspective-origin: 50% -50%;
  position: relative;

  h3,
  h4 {
    animation: ${Dropdown} 0.6s ease-out;
    font-family: "Gabarito", sans-serif;
    font-weight: normal;
    font-size: 20vh;
    letter-spacing: -1vh;
    line-height: 9vh;
    margin: 0;
    padding: 5vh;
    text-align: center;
    text-transform: uppercase;
    top: 1vh;
    opacity: 0.8;
    transform: rotateX(40deg) scaleX(100%) scaleY(80%) translateY(18vh);
    position: absolute;

    span {
      display: block;
    }

    // background: linear-gradient(rgba(84, 18, 40, 0.55), #b01852);
    background: linear-gradient(
      360deg,
      rgb(157, 1, 87) 0%,
      rgb(157, 1, 87) 5%,
      rgb(114, 17, 40) 13%,
      rgb(252, 121, 48) 25%,
      rgb(255, 199, 11) 27%,
      rgb(115, 192, 43) 31%,
      rgb(7, 215, 160) 55%,
      rgb(0, 127, 168) 72%,
      rgb(35, 38, 93) 83%,
      rgb(82, 25, 72) 90%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    .of {
      background: white;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: white;
      letter-spacing: 0.3vh;
      font-weight: bold;
      font-stretch: expanded;
      font-size: 5vh;
      line-height: 12.5vh;
    }
  }

  h3 {
    filter: brightness(1.5) saturate(110%);
  }

  h4 {
    filter: blur(1vh) brightness(0.8) saturate(140%);
    text-shadow: 0 0 2vh rgba(0, 0, 0, 0.7);
    opacity: 0.7;
  }

  button {
    font-size: 2.5vh;
    height: fit-content;
    width: auto;
    transform: translateY(-4vh);
    color: white;
    opacity: 1;
    position: relative;
    top: 81vh;
  }
`;
export const Title = styled.div`
  h3 {
    white-space: nowrap;
    font-family: "Gabarito", sans-serif;
    font-weight: 700;
    font-size: 7.3vw;
    letter-spacing: 0.2vw;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    width: 100%;
    margin: 0;
    .of {
      display: inline-block;
      background: white;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: white;
      font-size: 2vw;
      line-height: 4vw;
      position: relative;
      top: -0.8vh;
      margin: 0 1vh;
    }
  }
  @media (min-aspect-ratio: 4/5) {
    h3 {
      font-size: 5vh;
      letter-spacing: 0.2vh;
    }
    .of {
      font-size: 2vh;
    }
  }
  background: linear-gradient(
    360deg,
    rgb(157, 1, 87) 0%,
    rgb(157, 1, 87) 20%,
    rgb(114, 17, 40) 35%,
    rgb(252, 121, 48) 55%,
    rgb(255, 199, 11) 63%,
    rgb(115, 192, 43) 69%,
    rgb(7, 215, 160) 72%,
    rgb(0, 127, 168) 76%,
    rgb(53, 58, 140) 80%,
    rgb(143, 47, 126) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const Tower = styled.div`
  padding-bottom: 3vh;
  position: absolute;
  bottom: 24vh;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Button = styled.button`
  border-radius: 100px;
  color: ${sc("text_btn")};
  font-family: Montserrat;
  padding: 0.75em 1.5em;
  box-shadow: 0 1vh 0.2vh 0 rgba(56, 16, 59, 0.2);
  font-size: 3vh;
  ${GlassEffect("rgba(100,5,56,0.82)", 0.1)}
  border: 0.1vh solid  rgb(117, 23, 61);

  &:hover {
    ${GlassEffect("rgba(68,8,44,0.95)", 0.3)}
    border: 0.1vh solid rgba(122, 17, 74, 0.82);
  }
`;

type WordProps = {
  count: number;
  index: number;
  errors: number;
};

const getStackColor = (index: number = 0) => {
  switch (index) {
    case 0:
      return "rgb(124,19,72)";
    case 1:
      return "rgb(103,18,31)";
    case 2:
      return "rgb(211,106,49)";
    case 3:
      return "rgb(255,205,0)";
    case 4:
      return "rgb(154,199,112)";
    case 5:
      return "rgb(31,128,110)";
    case 6:
      return "rgb(17,128,169)";
    case 7:
      return "rgb(80,123,203)";
    case 8:
      return "rgb(107,47,95)";
    case 9:
      return "rgb(152,59,115)";
    case 10:
      return "rgb(229,177,206)";
    default:
      return "rgb(59,59,59)";
  }
};
const glowColor = (index: number) =>
  transparentize(0.3, saturate(0.5, getStackColor(index)));

export const TowerWord = styled.div<WordProps>`
  align-items: center;
  border-radius: 3vh;
  color: ${({ errors }) => transparentize(errors * 0.25, "white")};
  display: flex;
  margin-bottom: 0.3vh;
  font-size: 3.5vh;
  letter-spacing: 0.5vh;
  font-weight: 700;
  height: 5.5vh;
  justify-content: center;
  padding: 0.2vh 6vh;
  -webkit-font-smoothing: antialiased;
  width: 5vh;
  order: ${({ count, index }) => count - index};
  ${({ errors, index }) =>
    GlassEffect(
      darken(
        0.2 * errors + 1,
        transparentize(0.5, desaturate(0.7 * errors, getStackColor(index)))
      ),
      0.2 + Math.random() / 10
    )}
  ${({ errors, index }) =>
    NeonGlow(
      transparentize(
        0.2 + 0.05 * errors,
        darken(0.1 * errors, glowColor(index))
      ),
      0.2 - 0.1 * errors,
      false,
      true
    )}
  ${({ errors, index }) =>
    NeonGlow(lighten(errors * 0.05, glowColor(index)), 0.5, true)}
  ${({ errors }) => (errors > 0 ? BrokenEffect(errors) : "")}
  transform: rotate(${({ count, errors, index }) =>
    count === index && errors === 0
      ? 0
      : signedRandom(1.2 * errors * index, errors === 0 ? 0 : 2)}deg)
  translateX(${({ count, errors, index }) =>
    signedRandom((index - 1) * errors * 1.2)}%);
  border: 0.1vh solid ${({ index }) => getStackColor(index)};
  transition: transform 20ms ease-in-out;
`;

type GoalLineProps = {
  index: number;
  errors: number;
  last: boolean;
};

export const GoalLine = styled.div<GoalLineProps>`
  border-top: 0.5vh dotted rgb(117, 23, 61);
  height: 7vh;
  width: 250vh;
  position: absolute;
  left: -100vh;
  bottom: ${({ index }) => 6.8 + index * 6}vh;
  visibility: ${({ last }) => (!last ? "hidden" : "visible")};
  pointer-events: none;
`;
