import styled from "styled-components";
import sushi from "../../styles/assets/sushi.png";
import tabletop from "../../styles/assets/tabletop.jpg";
import { FadeIn, NeonGlow, FlickerIn, GlassEffect } from "../../styles/effects";
import { signedRandom } from "../_shared/utils";
export const ERROR_TIMEOUT = 4000;

const PlateElement = styled.div<PlateProps>`
  align-items: center;
  display: flex;
  margin: 0.77vh;
  width: fit-content;
  position: relative;
  flex-direction: ${({ horizontal }) => (horizontal ? "column" : "row")};
  .image {
    width: 13vh;
    height: 13vh;
    background: url(${sushi});
    ${({ horizontal }) =>
      horizontal ? "top: -22vh; left: 0.5vh;" : "top: 0.5vh; left: -20vh;"}
    background-size: 87vh;
    position: relative;
    filter: brightness(1.1) contrast(1.05);
    opacity: 0.8;
    &[data-variation="2"] {
      background-position: 17.3% 0;
    }

    &[data-variation="3"] {
      background-position: 33.6% 0;
    }

    &[data-variation="4"] {
      background-position: 50.9% 0;
    }

    &[data-variation="5"] {
      background-position: 67.2% 0;
    }

    &[data-variation="6"] {
      background-position: 84.5% 0;
    }

    &[data-variation="7"] {
      background-position: 101% 0;
    }
    &:nth-child(1) {
      order: 2;
    }
    &:nth-child(2) {
      order: 1;
    }
  }
  width: ${({ horizontal }) => (horizontal ? "12.5vh" : "fit-content")};
  p {
    border-radius: 5px;
    color: #fff6ea;
    display: flex;
    background: url(${tabletop});
    background-size: 40vh;
    border: 0.2vh solid #8f3939;
    filter: contrast(1.1) hue-rotate(-60deg);
    font-family: "Gabarito", sans-serif;
    font-size: 6vh;
    line-height: ${({ horizontal }) => (horizontal ? "6vh" : "10.5vh")};
    margin: ${({ horizontal }) => (horizontal ? "0 auto" : "0")};
    font-style: normal;
    font-weight: 400;
    justify-content: ${({ horizontal }) =>
      horizontal ? "center" : "flex-end"};
    padding: ${({ horizontal }) => (horizontal ? "4vh 1vh" : "1.1vh 2vh")};
    width: ${({ horizontal }) => (horizontal ? "80%" : "14vh")};
    text-shadow: 0.1vh 0.1vh 1.2vh rgba(0, 0, 0, 1);
  }
  ${({ horizontal }) => (horizontal ? "top: 24vh; left: 3vh" : "left: 22vh")};
  &.right {
    ${({ horizontal }) => (horizontal ? "top: 61vh; left: 3vh" : "left: 64vh")};
    .image {
      ${({ horizontal }) =>
        horizontal ? "top: -4vh; left: 0.5vh;" : "top: 0.5vh; left: -9.5vh;"}
    }
    p {
      justify-content: ${({ horizontal }) =>
        horizontal ? "center" : "flex-start"};
      padding: ${({ horizontal }) =>
        horizontal ? "1vh 1vh 3vh" : "1.1vh 2vh"};
      line-height: 10.5vh;
    }
  }
`;
type PlateProps = {
  error?: boolean;
  horizontal?: boolean;
  rune?: string;
  right?: boolean;
  variation?: number;
};

export const Plate = (props: PlateProps) => (
  <PlateElement
    {...props}
    className={`${props.right ? "right" : ""} ${props.error ? "error" : ""}`}
  >
    <p>{props.rune}</p>
    <div
      className="image"
      data-variation={(
        props.variation || Math.round(Math.random() * 7)
      ).toString()}
    />
  </PlateElement>
);

type PlateSetProps = {
  horizontal?: boolean;
  top?: number;
};

export const PlateSet = styled.div<PlateSetProps>`
  position: absolute;
  transform: ${({ horizontal }) =>
    horizontal ? "translateX(-100%)" : "translateY(-100%)"};
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? "row" : "column")};
  &.error {
    animation: ${FlickerIn} ${ERROR_TIMEOUT}ms;
    .image {
      display: none;
    }
  }
`;
type TableRowProps = {
  horizontal?: boolean;
};

export const TableRow = styled.div<TableRowProps>`
  position: absolute;
  top: 0.5vh;
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? "row" : "column")};
  gap: 0.5vh;
  width: 100vw;
  height: 100vw;
  align-items: center;
  justify-content: center;
  transform: translateX(2vh);
`;

type TableProps = {
  className?: string;
  rune?: string;
  variation?: number;
};

const TableElement = styled.div.attrs<TableProps>(({ variation }) => ({
  style: {
    transform: `rotate(${signedRandom(2 * (variation || 1))}deg)`,
    filter: `brightness(
      ${1.8 + signedRandom(0.05 * (variation || 0))}
      )
      contrast(1.1)
      hue-rotate(
        ${-65 + signedRandom(5 * (variation || 0))}deg
      )`,
  },
}))<TableProps>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  &.allow {
    visibility: hidden;
    pointer-events: none;
  }
  &.forbid {
    pointer-events: none;
    mix-blend-mode: multiply;
    opacity: 0.3;
  }
  h2 {
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
    width: 13vh;
    height: 13vh;
    color: #f8ddb8;
    display: flex;
    margin: 0;
    border-radius: 100%;
    background: url(${tabletop});
    background-size: 20vh;
    border: 0.2vh solid #3f2020;
    font-family: "Gabarito", sans-serif;
    font-size: 6vh;
    line-height: 6.7vh;
    font-style: normal;
    font-weight: 400;
    flex-basis: 13vh;
  }
`;

type TableHighlightProps = {
  error?: boolean;
  highlight?: boolean;
};

export const TableHighlight = styled.div<TableHighlightProps>`
  animation: ${FadeIn} 100ms;

  h2 {
    align-items: center;
    border-radius: 100%;
    border: 0.2vh solid ${({ error }) => (error ? "#fff" : "transparent")};
    justify-content: center;
    aspect-ratio: 1;
    width: 13vh;
    height: 13vh;
    color: transparent;
    display: flex;
    margin: 0;
    pointer-events: none;
    mix-blend-mode: ${({ error }) => (error ? "multiply" : "normal")};
    ${({ highlight, error }) =>
      NeonGlow(
        error ? "rgba(28,10,20,0.18)" : "rgba(239,61,134,0.13)",
        highlight ? 0.5 : 0.02,
        false,
        true
      )}
    font-family: "Gabarito", sans-serif;
    background: ${({ error }) => (error ? "rgba(0,0,0,0.35)" : "transparent")};
    font-size: 7.5vh;
    line-height: 7.7vh;
    font-style: normal;
    font-weight: 400;
    flex-basis: 13vh;
    visibility: ${({ highlight }) => (highlight ? "visible" : "hidden")};
  }
`;

const ChairElement = styled.div`
  border-radius: 1vh;
  width: 1vh;
  height: 0.2vh;
`;

export const Table = (props: TableProps) => (
  <TableElement
    className={props.className}
    variation={props.variation || Math.round(Math.random() * 7)}
  >
    <h2>
      <span>{props.rune}</span>
    </h2>
  </TableElement>
);

export const TableClosed = (props: TableProps) => (
  <TableElement
    className={props.className}
    variation={props.variation || Math.round(Math.random() * 7)}
  >
    <h2>
      <span>{props.rune}</span>
    </h2>
  </TableElement>
);

export const Title = styled.div`
  display: flex;
  text-align: center;
  justify-content: space-around;
  h2 {
    width: 6.6vw;
    max-width: 6.6vw;
    height: 6.6vw;
    font-size: 3vw;
    margin: 0;
  }
  .spacer {
    width: 3vh;
  }
  .apostrophe {
    display: inline-block;
    font-size: 3vw;
    left: -11vw;
    top: 1.5vw;
    position: relative;
  }
  @media (min-aspect-ratio: 4/5) {
    h2 {
      width: 4vw;
      max-width: 4vw;
      height: 4vw;
      font-size: 1.7vw;
    }
    .apostrophe {
      left: -5.7vw;
      top: 1vw;
      font-size: 2vw;
    }
  }
  @media (min-aspect-ratio: 1/1) {
    h2 {
      width: 5vh;
      max-width: 5vh;
      height: 5vh;
      font-size: 2vh;
    }
    .apostrophe {
      left: -7.2vh;
      top: 1.3vh;
      font-size: 2vh;
    }
  }
`;

export const Splash = styled.div`
  align-items: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    display: flex;
    gap: 0.25vh;
    margin: 0;
    .apostrophe {
      transform: rotate(8deg);
      color: white;
      font-size: 5vh;
      position: relative;
      left: -18.3vh;
      top: 3vh;
    }
    .t-2 {
      h2 span {
        display: block;
        transform: translateX(-0.5vh);
      }
    }
  }
  button {
    margin: 1vh;
    font-family: Montserrat, sans-serif;
    font-size: 2.5vh;
    box-shadow: none;
    display: inline;
  }
  .actions {
    margin: 5vh 0 5vh;
  }
`;

type GameContainerProps = {
  horizontal?: boolean;
  gameOver?: boolean;
};
export const GameContainer = styled.div<GameContainerProps>`
  height: 100vh;
  user-select: none;
  width: 100vh;
  cursor: pointer;
  padding: 1vh;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  transform: scale(0.85)
    ${({ horizontal }) =>
      horizontal ? " translateX(6vh) " : " translateY(7vh) "};
  position: relative;

  .save {
    color: #44253c;
    position: absolute;
    font-size: 3vh;
    border: 1px solid #4d2743;
    border-radius: 100%;
    padding: 2vh;
    ${({ horizontal }) =>
      horizontal ? "top: -4vh; left: -12vh" : "top: -12vh; left: -3vh"};
    width: 3vh;
    height: 3vh;
    text-align: center;

    &:hover {
      color: rgb(121, 72, 128);
      border: 1px solid #8c4d7b;
    }
  }

  .score {
    font-family: "Gabarito", sans-serif;
    font-size: 4vh;
    color: white;
    position: absolute;

    ${({ horizontal }) =>
      horizontal ? "top: -6vh; right: 4vh" : "top: -13vh; right: -2vh"};
  }

  .recent {
    position: absolute;
    color: #ceb9a2;
    font-family: "Gabarito", sans-serif;
    font-size: 9vh;
    font-weight: 400;
    opacity: 0.2;
    mix-blend-mode: soft-light;
    display: flex;
    width: 100vh;
    flex-direction: ${({ horizontal }) => (horizontal ? "row" : "column")};
    gap: ${({ horizontal }) => (horizontal ? "5vh" : "1vh")};
    h1 {
      margin: 0;
      line-height: 22vh;
      text-align: ${({ horizontal }) => (horizontal ? "center" : "right")};
      flex-basis: ${({ horizontal }) => (horizontal ? "25%" : "20%")};
    }
    ${({ horizontal }) => (horizontal ? "transform: scale(1.1);" : "")}
    ${({ horizontal }) =>
      horizontal ? "bottom: -6vh; left: -6vh" : "top: -12vh; right: -2vh"};
  }
`;

export const ErrorIndicator = styled.div`
  align-items: center;
  //content: "ERROR";
  display: flex;
  height: 100vh;
  font-family: "Gabarito", sans-serif;
  font-size: 25vh;
  justify-content: center;
  position: absolute;
  width: 100vh;
`;

type PreviewProps = {
  className?: string;
  current?: boolean;
  horizontal?: boolean;
  word?: {
    word: string[];
    left: boolean;
  } | null;
  key: string;
};
export const Preview = styled.div<PreviewProps>`
  color: white;
  display: flex;
  position: absolute;
  border: 1px solid white;
  width: 10vh;
  height: 10vh;
  transform: ${({ horizontal }) =>
    horizontal
      ? "translateY(45vh) translateX(-15vh)"
      : "translateX(47vh) translateY(-15vh)"};
  animation: ${FadeIn} ${ERROR_TIMEOUT}ms both;
  opacity: 0;
  h2 {
    font-family: "Gabarito", sans-serif;
    font-size: 5vh;
    font-style: normal;
    font-weight: 400;
    line-height: 12vh;
    position: relative;
    text-align: center;
    top: -4.8vh;
    width: 100vh;
  }
  i {
    position: absolute;
    font-size: 3vh;
    opacity: 0.5;
  }
  .fa-arrow-right {
    ${({ horizontal }) =>
      horizontal
        ? "transform: rotate(90deg) translateY(-3vh) translateX(11vh)"
        : "transform: translateX(12vh) translateY(3.5vh);"}
  }
  .fa-arrow-left {
    ${({ horizontal }) =>
      horizontal
        ? "transform: rotate(90deg) translateY(-3vh) translateX(-4vh);"
        : "transform: translateX(-4vh) translateY(3.5vh);"}
  }
`;

export const Collection = styled.div<GameContainerProps>`
  ${GlassEffect("rgba(21,16,20,0.5)", 0.3)};
  ${NeonGlow("rgba(44,18,32,0.1)", 7, false, true)}
  border: 0.1vh solid #814e69;
  border-radius: 6vh;
  backdrop-filter: blur(2vh) brightness(0.2) saturate(1.2);
  color: #7a406c;
  display: flex;
  flex-direction: column;
  font-size: 3vh;
  height: 100vh;
  width: 100vh;
  z-index: 1000;
  position: relative;
  
  ${({ horizontal }) =>
    horizontal
      ? "transform: translateX(-7vh) translateY(-0.5vh) scale(1.1);"
      : "transform: translateY(-8vh) scale(1.1);"}
  
  div.wordlist {
    padding: 3vh 10vh;
    column-count: 7;
    ${({ gameOver }) =>
      gameOver ? "transform: scale(0.8) translateY(-8vh);" : ""}
  }
  button {
    color: white;
    font-size: 2vh;
    box-shadow: none;
    margin-top: 2vh;
  }
  .actions {
    position: relative;
    top: -15vh;
    text-align: center;
  }
  h2 {
    color: #af81a4;
    font-weight: 400;
  }
  h3 {
    padding: 7vh 0 2vh;
    font-size: 5vh;
    font-weight: 800;
  }

  h3, h2,
  p {
    margin: 0 0 0.3vh 0;
    text-align: center;
    font-family: "Gabarito", sans-serif;
  }

  h3,
  p.found {
    color: white;
  }

  p.found {
    ${NeonGlow("rgba(217,217,217,0.3)", 0.2, true)}
  }}

`;
