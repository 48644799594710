import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Machine } from "../_shared/Machine";
import {
  Collection,
  ERROR_TIMEOUT,
  ErrorIndicator,
  GameContainer,
  Plate,
  PlateSet,
  Preview,
  Splash,
  Table,
  TableClosed,
  TableHighlight,
  TableRow,
  Title,
} from "./elements";
import {
  filterToUnique,
  randomSelect,
  sendEvent,
  shuffle,
} from "../_shared/utils";
import { ButtonSecondary } from "../../styles/sharedStyles";
import {
  findSegment,
  getOrdersUp,
  minimalWordList,
  OrderUpType,
} from "./utils";
import { wordData } from "./wordData";
import { random } from "lodash";
import { twos } from "../../data/2";
const MIN_SPEED = 2;
const MAX_SPEED = 90;
export const LetsRoll = () => {
  const [speed, setSpeed] = useState(MIN_SPEED);
  const [horizontal, setHorizontal] = useState(randomSelect([true, false]));
  const [paused, setPaused] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [guestsToSee, setGuestsToSee] = useState(
    shuffle(Array.from(minimalWordList))
  );
  const [currentGuests, setCurrentGuests] = useState(new Array<string>());
  const [ordersUpLeft, setOrdersUpLeft] = useState(new Set<OrderUpType>());
  const [ordersUpRight, setOrdersUpRight] = useState(new Set<OrderUpType>());
  const [activeSegment, setActiveSegment] = useState<number | null>();
  const [positionTop, setPositionTop] = useState(-10);
  const [score, setScore] = useState(0);
  const [error, setError] = useState(false);
  const animationTime = useRef<ReturnType<typeof setInterval> | null>(null);
  const [twosSeen, setTwosSeen] = useState<string[]>([]);
  const [gameOver, setGameOver] = useState<boolean>(false);

  const analyticsData = useMemo(
    () => ({
      game: "letsroll",
      score,
      speed,
      twosSeen: twosSeen.length,
    }),
    [score, speed, twosSeen]
  );

  useEffect(() => {
    document.title = "StudyCade | Let's Roll! Learn short words on StudyCade";
    sendEvent("page_view", {
      page_title: "letsroll",
      page_location: "/#/letsroll",
    });
    sendEvent("machine_load", { game: "letsroll" });
  }, []);

  const recentGets = useMemo(
    () => twosSeen.slice(horizontal ? -4 : -5),
    [horizontal, twosSeen]
  );
  // The piece(s) of sushi to be delivered currently
  const [nextWord, setNextWord] = useState<{
    word: string[];
    left: boolean;
  } | null>();
  const [currentWord, setCurrentWord] = useState<{
    word: string[];
    left: boolean;
  } | null>();

  useEffect(() => {
    if (!guestsToSee.length && !currentGuests.length) {
      setGameOver(true);
      setGameStarted(false);
    }
  }, [currentGuests, guestsToSee]);
  useEffect(() => {
    const animate = () => {
      requestAnimationFrame(() => {
        setPositionTop((x) => {
          if (x < 140) {
            return x + 0.15;
          }
          setCurrentWord(null);
          setSpeed((x) => (x * 0.5 >= MIN_SPEED ? x * 0.5 : MIN_SPEED));
          return -50;
        });
      });
    };
    //debugger;
    if (!animationTime.current && gameStarted && !paused && currentWord?.word) {
      animationTime.current = setInterval(animate, 30 / speed);
    }
    if ((paused || !currentWord?.word) && animationTime.current) {
      clearInterval(animationTime.current);
      animationTime.current = null;
    }
    if (paused && error) {
      setTimeout(() => {
        setPositionTop(-50);
      }, ERROR_TIMEOUT);
    }
    return () => {
      clearInterval(animationTime.current!);
      animationTime.current = null;
    };
  }, [currentWord, error, gameStarted, paused, speed]);
  useEffect(() => {
    if (currentWord?.word) {
      setActiveSegment(findSegment(positionTop - 1.5 * (100 / 7), 7) || null);
    }
  }, [error, positionTop, currentWord, speed]);
  useEffect(() => {
    const ROUND_SIZE_PER_SIDE = 7; //ideal, may exceed
    const MAX_ADJACENT_PER_SIDE =
      speed > 70 ? 5 : speed > 40 ? 2 : speed > 10 ? 1 : 0;
    if (!(currentGuests.length > 0) && guestsToSee.length) {
      const nextWord = guestsToSee[0];
      // Pick 10, starting with essentials, trying to balance
      const ordersUp = getOrdersUp(nextWord, {
        perSide: ROUND_SIZE_PER_SIDE,
        maxParallel: MAX_ADJACENT_PER_SIDE,
        wordData,
      });
      if (localStorage.getItem("letsroll-admin")) {
        console.warn(wordData[nextWord], ordersUp);
      }

      setOrdersUpLeft(new Set(ordersUp.ordersUpLeft));
      setOrdersUpRight(new Set(ordersUp.ordersUpRight));
      setPositionTop(-50);
      setHorizontal((x) => !x);
      setCurrentWord(null);
      setCurrentGuests(nextWord.split(""));
      setGuestsToSee((x) => x.slice(1));
    }
  }, [currentGuests, gameStarted, guestsToSee, speed]);
  useEffect(() => {
    if (!currentWord && currentGuests?.length > 0 && gameStarted) {
      // Randomly select a side, unless one is empty
      if (!ordersUpLeft.size && !ordersUpRight.size) {
        setNextWord(null);
        setCurrentGuests([]);
        setPositionTop(-50);
        return;
      }
      const poolLeft = new Set(ordersUpLeft);
      const poolRight = new Set(ordersUpRight);
      let newWord;
      if (nextWord?.left) {
        const cur = [...poolLeft].find(
          (o) => o.tile === nextWord.word!.join("")
        );
        if (cur) {
          poolLeft.delete(cur);
        }
      } else {
        if (nextWord?.word) {
          const cur = [...poolRight].find(
            (o) => o.tile === nextWord.word!.join("")
          );
          if (cur) {
            poolRight.delete(cur);
          }
        }
      }
      let left =
        (randomSelect([true, !poolRight?.size]) || false) &&
        (!!poolLeft.size || false);
      const pool = left ? poolLeft : poolRight;
      if (!poolLeft.size && !poolRight.size) {
        // Last word. Gotta keep it
        newWord = nextWord?.word.join("");
        left = nextWord?.left || false;
      }
      if (!newWord) {
        newWord = randomSelect(pool)?.tile;
      }
      if (nextWord) {
        setCurrentWord(nextWord);
        setForbidden(
          nextWord.left
            ? [...ordersUpLeft].find((o) => o.tile === nextWord.word.join(""))
                ?.forbidden || []
            : [...ordersUpRight].find((o) => o.tile === nextWord.word.join(""))
                ?.forbidden || []
        );
      }
      if (newWord) {
        setNextWord({ word: newWord!.split(""), left });
      } else {
        setNextWord(null);
      }
    }
  }, [
    currentGuests,
    currentWord,
    nextWord,
    gameStarted,
    ordersUpLeft,
    ordersUpRight,
  ]);
  const renderGuests = useMemo(
    () => (
      <TableRow horizontal={horizontal}>
        {currentGuests.length > 0 &&
          currentGuests.map((r, i) => <Table rune={r} key={r} />)}
      </TableRow>
    ),
    [currentGuests, horizontal]
  );

  const [forbidden, setForbidden] = useState<number[]>([]);
  const renderForbid = useMemo(() => {
    if (!currentWord) {
      return null;
    }

    return (
      <TableRow horizontal={horizontal}>
        {currentGuests.length > 0 &&
          currentGuests.map((r, i) => (
            <TableClosed
              className={forbidden.includes(i) ? "forbid" : "allow"}
            />
          ))}
      </TableRow>
    );
  }, [currentGuests, currentWord, horizontal, forbidden]);

  const renderHighlight = useMemo(() => {
    if (positionTop < 0 || positionTop > 100) {
      return null;
    }
    return (
      <TableRow horizontal={horizontal}>
        {currentGuests.length > 0 &&
          currentGuests.map((r, i) => (
            <TableHighlight
              className={error ? "error" : ""}
              highlight={
                i ===
                  (currentWord?.word?.length === 2 &&
                    (activeSegment || 0) - 1) || i === (activeSegment || 0)
              }
              error={error}
            >
              <h2>{r}</h2>
            </TableHighlight>
          ))}
      </TableRow>
    );
  }, [
    activeSegment,
    currentGuests,
    currentWord,
    error,
    horizontal,
    positionTop,
  ]);

  const renderSushi = useMemo(() => {
    const variation = random(1, 7);
    return (
      <>
        {Array.from(currentWord?.word || []).map((l, i) => (
          <Plate
            key={`${currentGuests.join("")}-${currentWord?.word?.join(
              ""
            )}-${l}-${i}`}
            horizontal={horizontal}
            right={!currentWord?.left}
            rune={l}
            variation={
              variation + 1 + i < 8 ? variation + i + 1 : variation + i - 6
            }
          />
        ))}
      </>
    );
  }, [currentGuests, currentWord, horizontal]);

  useEffect(() => {
    if (error) {
      setPaused(true);
      setTimeout(() => {
        setError(false);
        setPaused(false);
      }, ERROR_TIMEOUT);
    }
  }, [error]);

  const checkOrder = useCallback(() => {
    const checkWords = () => {
      if (forbidden.includes(activeSegment!)) {
        return { valid: false, wordsChecked: [] as string[] };
      }
      let valid = true;
      const { word, left } = currentWord || {};
      const checkMe = Array.from(word || []).reverse();
      const wordsChecked = [] as string[];
      (checkMe || []).forEach((l, i) => {
        if (left) {
          const w = `${l}${currentGuests[activeSegment! - i]}`;
          valid = valid && twos.includes(w);
          wordsChecked.push(w);
        } else {
          const w = `${currentGuests[activeSegment! - i]}${l}`;
          valid = valid && twos.includes(w);
          wordsChecked.push(w);
        }
      });
      return { valid, wordsChecked };
    };
    const valid = checkWords();
    if (currentWord && valid?.valid) {
      // Yay! It matched.
      setCurrentWord(null);
      setPositionTop(-50);
      setSpeed((x) => (x * 1.1 < MAX_SPEED ? x * 1.1 : MAX_SPEED));
      // We should add the words they found to their collection.
      filterToUnique(valid.wordsChecked).forEach((w) => {
        if (!twosSeen.includes(w)) {
          setTwosSeen((x) => x.concat(w));
          // 50 per word found, plus bonus 50 for the first time found
          setScore((x) => x + 50);
        }
      });
      setScore((x) => x + valid.wordsChecked.length * 50);
      if (currentWord.left) {
        setOrdersUpLeft((x) => {
          const t = new Set(x);
          const cur = [...x].find((o) => o.tile === currentWord.word!.join(""));
          if (cur) {
            t.delete(cur);
          }
          return t;
        });
      } else {
        if (currentWord) {
          setOrdersUpRight((x) => {
            const t = new Set(x);
            const cur = [...x].find(
              (o) => o.tile === currentWord.word!.join("")
            );
            if (cur) {
              t.delete(cur);
            }
            return t;
          });
        }
      }
    } else {
      // Aw, man.
      if (currentWord) {
        setError(true);
        setSpeed((x) => (x * 0.5 >= MIN_SPEED ? x * 0.5 : MIN_SPEED));
      }
    }
  }, [activeSegment, currentGuests, currentWord, forbidden, twosSeen]);
  const serializeState = useCallback(() => {
    localStorage.setItem(
      "letsroll",
      JSON.stringify({
        currentGuests: [...currentGuests],
        currentWord: { word: currentWord?.word, left: currentWord?.left },
        nextWord: { word: nextWord?.word, left: nextWord?.left },
        guestsToSee: [...guestsToSee],
        horizontal,
        forbidden: [...forbidden],
        ordersUpLeft: [...ordersUpLeft],
        ordersUpRight: [...ordersUpRight],
        twosSeen: [...twosSeen],
        speed,
        score,
        version: 2,
      })
    );
  }, [
    currentGuests,
    currentWord,
    forbidden,
    horizontal,
    nextWord,
    guestsToSee,
    score,
    speed,
    ordersUpRight,
    ordersUpLeft,
    twosSeen,
  ]);

  const handleInput = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();

      const { key } = e;
      const k = key.toUpperCase();

      if (k === "ESCAPE") {
        setPaused((x) => !x);
        setCurrentWord(null);
        setError(false);
        serializeState();
      }
      if (localStorage.getItem("letsroll-admin")) {
        if (k === ">") {
          setCurrentGuests([]);
        }
      }
      if (paused) {
        return;
      }
      if (k === " ") {
        checkOrder();
      }
    },
    [checkOrder, paused, serializeState]
  );
  useEffect(() => {
    document.addEventListener("keyup", handleInput);
    return () => {
      document.removeEventListener("keyup", handleInput);
    };
  }, [handleInput]);

  const previewWord = useMemo(
    () => (positionTop < -20 ? currentWord : nextWord),
    [currentWord, nextWord, positionTop]
  );

  const loadedGame = JSON.parse(localStorage.getItem("letsroll") || "{}");

  const renderTitle = useMemo(
    () => (
      <Title>
        {"Lets"
          .toUpperCase()
          .split("")
          .map((l, i) => (
            <Table
              rune={l}
              key={`${l}-${i}`}
              className={`splash ${l.toLowerCase()}-${i}`}
            />
          ))}
        <span className="apostrophe">&#x27;</span>
        <span className="spacer">&nbsp;</span>
        {"Roll"
          .toUpperCase()
          .split("")
          .map((l, i) => (
            <Table rune={l} key={`${l}-${i}`} className={`splash ${l}-${i}`} />
          ))}
      </Title>
    ),
    []
  );
  const renderSplash = useMemo(
    () => (
      <Splash>
        <h3>
          {"Lets"
            .toUpperCase()
            .split("")
            .map((l, i) => (
              <Table
                rune={l}
                key={`${l}-${i}`}
                className={`splash ${l.toLowerCase()}-${i}`}
              />
            ))}
          <span className="apostrophe">&#x27;</span>
        </h3>

        <h3>
          {"Roll!"
            .toUpperCase()
            .split("")
            .map((l, i) => (
              <Table
                rune={l}
                key={`${l}-${i}`}
                className={`splash ${l}-${i}`}
              />
            ))}
        </h3>
        <div className="actions">
          <ButtonSecondary
            onClick={() => {
              setGameStarted(true);
              setGuestsToSee(shuffle(Array.from(minimalWordList)));
              sendEvent("start_game", analyticsData);
            }}
          >
            New Game
          </ButtonSecondary>
          {loadedGame.version === 2 && (
            <ButtonSecondary
              onClick={() => {
                const gameState = JSON.parse(
                  localStorage.getItem("letsroll") || "{}"
                );
                setGameStarted(true);
                setGuestsToSee(gameState.guestsToSee);
                setCurrentGuests(gameState.currentGuests);
                setNextWord(gameState.nextWord);
                setCurrentWord(gameState.currentWord);
                setForbidden(gameState.forbidden);
                setHorizontal(gameState.horizontal);
                setScore(gameState.score);
                setSpeed(gameState.speed / 2);
                setPositionTop(-50);
                setTwosSeen(gameState.twosSeen);
                setOrdersUpRight(gameState.ordersUpRight);
                setOrdersUpLeft(gameState.ordersUpLeft);
                sendEvent("load_game", {
                  ...analyticsData,
                  score: gameState.score,
                  twosSeen: gameState.twosSeen?.length,
                });
              }}
            >
              Load Saved
            </ButtonSecondary>
          )}
        </div>
      </Splash>
    ),
    [analyticsData, loadedGame]
  );

  return (
    <Machine
      title={renderTitle}
      description="Learn the two letter words. Tap anywhere or press spacebar when the sushi gets to a matching table."
    >
      <div
        style={{ cursor: "pointer" }}
        onClick={
          !paused && !gameOver && gameStarted && currentGuests
            ? () => {
                checkOrder();
              }
            : !gameOver && gameStarted && currentGuests
            ? () => {
                setPaused(false);
              }
            : () => {}
        }
      >
        {gameOver && (
          <GameContainer>
            <Collection gameOver={gameOver}>
              <h3>Game Over! </h3>
              <h2>{score}</h2>
              <div className="wordlist">
                {[...twos].concat("").map((w) => (
                  <p className={twosSeen.includes(w) ? "found" : ""}>{w}</p>
                ))}
              </div>
              <div className="actions ">
                <ButtonSecondary
                  onClick={() => {
                    setGameStarted(true);
                    setGameOver(false);
                    setPaused(false);
                    setGuestsToSee(shuffle(Array.from(minimalWordList)));
                    setScore(0);
                    setTwosSeen([]);
                    setPositionTop(-50);
                    sendEvent("start_game", {
                      ...analyticsData,
                      fromGameOver: true,
                    });
                  }}
                >
                  New Game
                </ButtonSecondary>
              </div>
            </Collection>
          </GameContainer>
        )}
        {!gameOver && !gameStarted && !paused && renderSplash}
        {!gameOver && gameStarted && currentGuests && (
          <GameContainer horizontal={horizontal}>
            <>
              {renderGuests}
              {renderHighlight}
              {renderForbid}
              {(!paused || (paused && error)) && currentWord && (
                <PlateSet
                  className={`activeSegment ${activeSegment} ${
                    error ? "error" : ""
                  }`}
                  horizontal={horizontal}
                  key={`${currentGuests?.join("")}-${nextWord?.word?.join("")}`}
                  style={{
                    [horizontal ? "left" : "top"]: `${(
                      positionTop || 0
                    ).toString()}%`,
                  }}
                >
                  {renderSushi}
                </PlateSet>
              )}
              {error && <ErrorIndicator />}
              {!paused && (positionTop < 50 || positionTop > 50) && (
                <Preview
                  word={previewWord}
                  current={positionTop < 0 || positionTop > 100}
                  horizontal={horizontal}
                  key={previewWord?.word?.join("") || "unknown"}
                >
                  {previewWord?.left ? (
                    <i className="fa-solid fa-arrow-left" />
                  ) : null}
                  <h2>{previewWord?.word}</h2>
                  {!previewWord?.left ? (
                    <i className="fa-solid fa-arrow-right" />
                  ) : null}
                </Preview>
              )}
              <div
                className="save"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  serializeState();
                  setPaused(true);
                  sendEvent("pause_game", analyticsData);
                }}
              >
                <i className="fa-solid fa-pause" />
              </div>
              <div className="score">{score}</div>
              {!error && (
                <div className="recent">
                  {Array.from(recentGets).map((w, i) => (
                    <h1 key={`recent-${i}-${w}`}>{w}</h1>
                  ))}
                </div>
              )}
              {paused && !error && (
                <Collection horizontal={horizontal}>
                  <h3>Words Collected</h3>
                  <div className="wordlist">
                    {[...twos].concat("").map((w) => (
                      <p className={twosSeen.includes(w) ? "found" : ""}>{w}</p>
                    ))}
                  </div>
                </Collection>
              )}
            </>
          </GameContainer>
        )}
      </div>
    </Machine>
  );
};
