import { css, keyframes } from "styled-components";
import { CSSColor } from "./sharedStyles";
import color from "color";
import damaged from "./assets/broken.jpg";

const $glassColor = "gray";
export const GlassEffect = (c: CSSColor = $glassColor, opacity = 0.2) => {
  let baseColor = new color(c).alpha(opacity);
  return css`
    background: ${baseColor.toString()};
    backdrop-filter: blur(0.3px);
    border-color: ${baseColor.toString()};
  `;
};

export const BrokenEffect = (level: number) => {
  return css`
    overflow: hidden;
    &:before {
      content: " ";
      background: url(${damaged});
      width: 20vh;
      height: 20vh;
      position: absolute;
      background-size: 200%;
      background-repeat: repeat;
      opacity: calc(0.1 + (0.01 * ${level * Math.floor(Math.random() * 5)}));
      mix-blend-mode: difference;
      background-blend-mode: difference;
      transform: rotate(${Math.floor(Math.random() * 90 * level)}deg);
    }
    &:after {
      content: " ";
      background: url(${damaged});
      width: 20vh;
      height: 20vh;
      position: absolute;
      background-size: 200%;
      background-repeat: repeat;
      opacity: calc(0.2 + (0.1 * ${level}));
      mix-blend-mode: soft-light;
      background-blend-mode: overlay;
      transform: rotate(${Math.floor(Math.random() * 90 * level)}deg);
    }
  `;
};

export const NeonGlow = (
  c: CSSColor = "white",
  size = 4,
  text = false,
  inset = false
) => {
  const glowColor = new color(c);
  const propName = text ? "text-shadow" : "box-shadow";
  return css`
     {
      ${propName}: ${inset ? "inset" : ""} 0 0 ${size *
      2}vh ${glowColor.toString()},
      ${inset ? "inset" : ""} 0 0 ${size * 4}vh ${glowColor.toString()},
      ${inset ? "inset" : ""} 0 0 ${size * 6}vh ${glowColor.toString()},
      ${inset ? "inset" : ""} 0 0 ${size * 8}vh ${glowColor.toString()},
      ${inset ? "inset" : ""} 0 0 ${size * 12}vh ${glowColor.toString()},
      ${inset ? "inset" : ""} 0 0 ${size * 15}vh ${glowColor.toString()};
    }
  `;
};

export const FadeIn = keyframes`
  0% { opacity: 0}
  100% { opacity: 1}
`;

export const GrowIn = keyframes`
  0% {
    transform: scale(0.01) translateZ(0);
  }
  100% {
    transform: scale(1) translateZ(0);
  }
`;

export const Spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to { 
    transform: rotate(360deg);
  }
`;

export const FadeOut = keyframes`
  0% { opacity: 1}
  100% { opacity: 0}
`;

export const Explode = keyframes`
  0% { transform: scale(.8); }
  20% {  transform: scale(1); }
  100% {transform: scale(0); }
`;

export const Dropdown = keyframes`
  0% { transform: translateY(-100vh)}
  100% { transform: translateY(100vh)}
`;

export const FlickerIn = keyframes`
  0% { opacity: 0.2}
  4% { opacity: 0}
  8% { opacity: 0.2}
  9% { opacity: 0}
  10% { opacity: 0.8 }
  14% { opacity: 0.1}
  28% { opacity: 0.6}
  29% { opacity: 1 }
  30% { opacity: 0.2}
  58% { opacity: 0.8}
  59% { opacity: 1 }
  60% { opacity: 0.4}
  100% { opacity: 1}
`;
