import { temp } from "../../data/34innerdata";
import { fives } from "../../data/5innerdata";
import { CellSet } from "./hyperspace";

export const lines = (someText: string = temp) =>
  someText
    .trim()
    .split(/\r?\n/)
    .filter((x) => x.length > 0);

export const lines5 = lines(fives);

export type Subword = {
  word: string;
  length: number;
  frontHooks: Set<string>;
  backHooks: Set<string>;
};

export const subwords = (words: string[]) => {
  const wordMap = new Map<string, Subword>();
  const addOrUpdate = (
    word: string,
    { front = new Set<string>(), back = new Set<string>() }
  ) => {
    const sw: Subword = {
      word,
      length: word.length,
      frontHooks: new Set<string>(
        wordMap.get(word) ? [...wordMap.get(word)!.frontHooks, ...front] : front
      ),
      backHooks: new Set<string>(
        wordMap.get(word) ? [...wordMap.get(word)!.backHooks, ...back] : back
      ),
    };
    wordMap.set(word, sw);
  };
  words.forEach((w) => {
    const main = w.substring(1, w.length - 1);
    addOrUpdate(main, {});
    const frontInner = w.substring(2, w.length - 1);
    if (w.charAt(0) === "-") {
      addOrUpdate(frontInner, { front: new Set<string>(w.charAt(1)) });
    }
    const backInner = w.substring(1, w.length - 2);
    if (w.charAt(w.length - 1) === "-") {
      addOrUpdate(backInner, { back: new Set<string>(w.charAt(w.length - 2)) });
    }
  });
  const wordArray: Subword[] = [];
  for (let w of wordMap.values()) {
    wordArray.push(w);
  }
  return wordArray;
};

const words = subwords(lines());
const words5 = subwords(lines5);

export const choicesByLength = (length: number, wl: Subword[] = words) =>
  wl.filter((w) => w.length === length - 1); // We want the length of the finished word not the subword

// Don't include words that take a back S as their only hook
export const choicesByLengthNoMouse = (
  length: number,
  wl: Subword[] = words
) => {
  let wlTemp = [...wl];
  if (length === 5) {
    wlTemp = [...words5];
  }
  return wlTemp
    .filter((w) => w.length === length - 1)
    .filter(
      (w) =>
        !(
          w.backHooks.size === 1 &&
          w.backHooks.has("S") &&
          w.frontHooks.size === 0
        )
    );
};

export type Solution = {
  word: string;
  runes: Set<string>;
};

export const isFront = (pos: keyof CellSet) => !["top", "left"].includes(pos);

export type Solutions = {
  front: Solution[];
  back: Solution[];
};

export type CellType = {
  solution?: Solution;
  collectedRunes: string[];
};

export const getPoolsByLength = (length: number) => {
  const choices = choicesByLengthNoMouse(length);
  const pools: Solutions = {
    front: [] as Solution[],
    back: [] as Solution[],
  };
  choices.forEach((w) => {
    if (w.backHooks.size > 0) {
      if (!(w.backHooks.size === 1 && w.backHooks.has("S"))) {
        pools.back.push({ word: w.word, runes: w.backHooks });
      }
    }
    if (w.frontHooks.size > 0) {
      pools.front.push({ word: w.word, runes: w.frontHooks });
    }
  });
  return pools;
};

// Returns true and an updated version of the tile if there's a match,
// false and the given tile if not
// todo This probably needs deduping
export const checkSolution = (p: CellType, rune: string) => {
  if (p && rune) {
    console.warn(p.solution);
    if (p.solution?.runes.has(rune)) {
      if (!p.collectedRunes.includes(rune)) {
        return {
          tile: { ...p, collectedRunes: p.collectedRunes.concat(rune) },
          collected: true,
          dupe: false,
        };
      } else {
        return { tile: p, collected: true, dupe: true };
      }
    }
  }
  return { tile: p, collected: false, dupe: false };
};
