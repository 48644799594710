import * as CSS from "csstype";
import styled, {
  createGlobalStyle,
  DefaultTheme,
  FlattenSimpleInterpolation,
} from "styled-components";
import { Link } from "react-router-dom";

export type CSSColor = CSS.DataType.Color;

interface SCThemeColors {
  body_bg: CSSColor;
  text: CSSColor;
  text_btn: CSSColor;
  primary: CSSColor;
  secondary: CSSColor;
}

export interface SCTheme extends DefaultTheme {
  color: SCThemeColors;
  cabinet: {
    border?: string;
  };
  buttonOverrides?: FlattenSimpleInterpolation;
  buttonSecondaryOverrides?: FlattenSimpleInterpolation;
  logoOverrides?: FlattenSimpleInterpolation;
  navOverrides?: FlattenSimpleInterpolation;
}
/* Constants */
export const SCREEN_WIDTH = {
  xs: {
    min: 360,
    max: 599,
  },
  sm: {
    min: 600,
    max: 959,
  },
  md: {
    min: 960,
    max: 1279,
  },
  lg: {
    min: 1280,
    max: 1919,
  },
  xl: {
    min: 1920,
    max: 2700,
  },
};
export const ASIDE_WIDTH = 300;
export const BODY_PADDING = 24;
/* Components */
// get color
export const sc =
  (key: keyof SCThemeColors) =>
  ({ theme }: { theme: SCTheme }) => {
    return theme.color[key];
  };

export const GlobalStyles = createGlobalStyle<{ theme: SCTheme }>`
  body {
    background: ${sc("body_bg")};
    background-position: left 0px top 0%;
    background-size: 200%;
    color: ${sc("text")};
    margin: 0;
    overflow: hidden;
    height: 100%;
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    * {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
`;

export const Button = styled.button`
  background: ${sc("primary")};
  color: ${sc("text_btn")};
  cursor: pointer;
  border: 1px solid ${sc("primary")};
  font-family: Montserrat;
  padding: 0.75em 1.5em;
  ${(props) => props.theme.buttonOverrides}
`;

export const ButtonSecondary = styled.button`
  background: ${sc("secondary")};
  color: ${sc("text_btn")};
  border: 1px solid ${sc("secondary")};
  padding: 0.75em 1.5em;
  ${(props) => props.theme.buttonSecondaryOverrides}
`;

export const LinkPlain = styled(Link)`
  text-decoration: none;
  color: inherit;
  width: auto;
`;
