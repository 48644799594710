export const twos = [
  "AA",
  "AB",
  "AD",
  "AE",
  "AG",
  "AH",
  "AI",
  "AL",
  "AM",
  "AN",
  "AR",
  "AS",
  "AT",
  "AW",
  "AX",
  "AY",
  "BA",
  "BE",
  "BI",
  "BO",
  "BY",
  "CH",
  "DA",
  "DE",
  "DI",
  "DO",
  "EA",
  "ED",
  "EE",
  "EF",
  "EH",
  "EL",
  "EM",
  "EN",
  "ER",
  "ES",
  "ET",
  "EW",
  "EX",
  "FA",
  "FE",
  "FY",
  "GI",
  "GO",
  "GU",
  "HA",
  "HE",
  "HI",
  "HM",
  "HO",
  "ID",
  "IF",
  "IN",
  "IO",
  "IS",
  "IT",
  "JA",
  "JO",
  "KA",
  "KI",
  "KO",
  "KY",
  "LA",
  "LI",
  "LO",
  "MA",
  "ME",
  "MI",
  "MM",
  "MO",
  "MU",
  "MY",
  "NA",
  "NE",
  "NO",
  "NU",
  "NY",
  "OB",
  "OD",
  "OE",
  "OF",
  "OH",
  "OI",
  "OK",
  "OM",
  "ON",
  "OO",
  "OP",
  "OR",
  "OS",
  "OU",
  "OW",
  "OX",
  "OY",
  "PA",
  "PE",
  "PI",
  "PO",
  "QI",
  "RE",
  "SH",
  "SI",
  "SO",
  "ST",
  "TA",
  "TE",
  "TI",
  "TO",
  "UG",
  "UH",
  "UM",
  "UN",
  "UP",
  "UR",
  "US",
  "UT",
  "WE",
  "WO",
  "XI",
  "XU",
  "YA",
  "YE",
  "YO",
  "YU",
  "ZA",
  "ZE",
  "ZO",
];
