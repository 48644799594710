export type PhaseTiming = {
  SEARCHING: number;
  NORMAL: number;
  MATCH: number;
  MISMATCH: number;
  FAIL: number;
  DESTROYED: number;
};

export type Config = {
  ACCELERATION_RATE: number;
  DAMAGE: number;
  DEBUG: boolean;
  HP_WARNING: number;
  INITIAL_SPEED: number;
  LOCK_DELAY: number;
  MAX_HP: number;
  MAX_LEVEL: number;
  MAX_SPEED: number; //36
  MIN_SPEED: number; //4
  MOUSE_ONLY: boolean;
  PHASE_TIMING: PhaseTiming;
  SIZE: 3 | 4 | 5;
  STEADY_SPEED: null | number; // where zero is infinitely fast
  USE_PREVIEW: boolean;
  WARN_TIMING: number;
};

export const configDefaults = {
  USE_PREVIEW: true,
  DAMAGE: 1,
  DEBUG: false,
  STEADY_SPEED: null, // where zero is infinitely fast
  MIN_SPEED: 3, //3
  MAX_SPEED: 24, //36
  MAX_LEVEL: 100,
  INITIAL_SPEED: 24, // where zero is infinitely fast
  PHASE_TIMING: {
    SEARCHING: 100,
    NORMAL: 3000,
    MATCH: 50,
    MISMATCH: 50,
    FAIL: 50,
    DESTROYED: 0,
  },
  LOCK_DELAY: 0.04,
  WARN_TIMING: 3000,
  MOUSE_ONLY: false,
  HP_WARNING: 2,
  SIZE: 3 as 3 | 4 | 5,
  MAX_HP: 5,
  ACCELERATION_RATE: 0.7,
};
