import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Machine } from "../_shared/Machine";
import {
  alphaSort,
  drawTiles,
  findAllWords,
  generateRack,
  getWordsByLength,
  sendEvent,
  sortByAlphagram,
} from "../_shared/utils";
import {
  Attempt,
  Button,
  ButtonContainer,
  Correct,
  Fail,
  GameContainer,
  Goal,
  GoalContainer,
  GoalFound,
  GoalMissed,
  Hint,
  HitPoints,
  LifeContainer,
  Rack,
  RackSummary,
  Scoreboard,
  SummaryContainer,
  SummaryTitle,
  Title,
  WordList,
} from "./elements";
import { getGarbageDist } from "../triad/triad";
import { shuffle } from "lodash";
import { Background } from "./back/Background";
import { Fortress } from "./fortress/Fortress";
import explode from "./explode.png";

const rackLength = 6;
export const maxWords = 24;
const startTime = Date.now();
const wordLength = 4;
const words = shuffle(getWordsByLength(wordLength));
const errorLimit = 5;

export const Fourtress = () => {
  const [rack, setRack] = useState<Array<string>>([]);
  const [displayRack, setDisplayRack] = useState<Array<string>>([]);
  const [initialized, setInitialized] = useState(false);
  const [paused, setPaused] = useState(false);
  const [attempt, setAttempt] = useState("");
  const [hint, setHint] = useState("");
  const [showSummary, setShowSummary] = useState(false);
  const [hintsUsed, setHintsUsed] = useState(0);
  const [errors, setErrors] = useState<number>(0);
  const feedbackTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [wordsFound, setWordsFound] = useState<Set<string>>(new Set());
  const [wordsFoundRound, setWordsFoundRound] = useState<Set<string>>(
    new Set()
  );
  const [correct, setCorrect] = useState<string>("");
  const [fail, setFail] = useState<boolean>(false);
  const [badRacks, setBadRacks] = useState<Set<string>>(new Set());
  const [timeElapsed, setTimeElapsed] = useState<number>(0);
  const [goalWords, setGoalWords] = useState<Array<string>>([]);
  const [wordsRemainingGame, setWordsRemainingGame] = useState(words.length);

  const allWordsFound = useMemo(
    () => goalWords.length > 0 && goalWords.length === wordsFoundRound.size,
    [goalWords, wordsFoundRound]
  );

  useEffect(() => {
    document.title = "StudyCade | Fourtress! Learn short words on StudyCade";
    sendEvent("page_view", {
      page_title: "fourtress",
      page_location: "/#/fourtress",
    });
    sendEvent("machine_load", { game: "fourtress" });
  }, []);

  const addToAttempt = useCallback(
    (letter: string) => {
      const newRack = displayRack.slice();
      const index = newRack.indexOf(letter);
      if (index > -1 && attempt.length < wordLength) {
        newRack.splice(index, 1, "");
        setDisplayRack(newRack);
        setAttempt((x) => x + letter);
      }
    },
    [displayRack, attempt]
  );

  const removeFromAttempt = useCallback(() => {
    const newRack = displayRack.slice();
    const letterToReturn = attempt[attempt.length - 1];
    setAttempt((x) => x.substring(0, x.length - 1));
    const index = rack.findIndex((l, i) => l === letterToReturn && !newRack[i]);
    if (index > -1) {
      newRack[index] = letterToReturn;
      setDisplayRack(newRack);
    }
  }, [attempt, displayRack, rack]);

  const restoreRack = useCallback(() => {
    setHint("");
    setDisplayRack(rack);
    setAttempt("");
  }, [rack]);

  const wordsToFind = useMemo(() => {
    return goalWords.filter((w) => !wordsFoundRound.has(w));
  }, [goalWords, wordsFoundRound]);

  useEffect(() => {
    if (attempt.length) {
      setHint("");
    }
    if (attempt.length === wordLength) {
      console.warn("correct");
      if (!wordsFoundRound.has(attempt)) {
        if (words.includes(attempt)) {
          setCorrect(attempt);
          setAttempt("");
          setWordsFoundRound((x) => new Set([...x, attempt]));
          if (wordsToFind.length !== 1) {
            restoreRack();
          }
        } else {
          console.warn("wrong");
          setErrors((x) => x + 1);
          setFail(true);
          restoreRack();
        }
      }
    }
  }, [attempt, restoreRack, wordsFoundRound, wordsFound, wordsToFind]);

  useEffect(() => {
    if (fail) {
      feedbackTimer.current = setTimeout(() => {
        setFail(false);
      }, 500);
    }
  }, [fail]);

  useEffect(() => {
    if (correct) {
      feedbackTimer.current = setTimeout(() => {
        setCorrect("");
      }, 1000);
    }
  }, [correct]);
  useEffect(() => {
    setWordsRemainingGame(
      words.length - new Set([...wordsFound, ...wordsFoundRound]).size
    );
  }, [wordsFound, wordsFoundRound]);

  const addHint = useCallback(() => {
    if (wordsToFind.length) {
      setHint((hint) =>
        attempt.length === 0 && hint.length < wordLength - 1
          ? hint + wordsToFind[0]?.[hint.length]
          : ""
      );
      setHintsUsed((x) => x + 1);
    }
  }, [attempt, wordsToFind]);

  const newWordCount = useMemo(
    () => goalWords.filter((word) => !wordsFound.has(word)).length,
    [goalWords, wordsFound]
  );

  useEffect(() => {
    console.info("bad racks:", badRacks);
  }, [badRacks]);

  useEffect(() => {
    if (!rack.length && (!initialized || wordsRemainingGame > 0)) {
      if (!words.filter((w) => !wordsFound.has(w)).length) {
        setWordsRemainingGame(0);
        return;
      }
      let newRack = generateRack(
        words.filter((w) => !wordsFound.has(w)),
        rackLength,
        maxWords,
        words,
        badRacks
      ).split("");
      let garbageToTry = getGarbageDist("");
      let bestRack = newRack;
      let bestRackCount = findAllWords(bestRack, words).length;
      console.log("br", bestRack);
      while (newRack.length < rackLength && garbageToTry.length) {
        const garbage = drawTiles(rackLength - newRack.length, garbageToTry);
        garbageToTry = garbageToTry
          .split("")
          .filter((letter) => !garbage.includes(letter))
          .join("");
        let tempRack = newRack.concat(garbage);
        let badDisplay = tempRack.join("");
        let attempts = 0;
        let giveUpNow = 0;
        const attemptedGarbage = "";
        let currentWords = findAllWords(tempRack, words);
        console.info(
          "attempting garbage",
          garbageToTry,
          garbage,
          `wordsFound: ${currentWords.length}`,
          newRack
        );

        while (currentWords.length > maxWords || bestRack.length < rackLength) {
          console.info("too many", currentWords.length, tempRack);

          setBadRacks((x) => new Set([...x, badDisplay]));
          attempts++;
          giveUpNow++;
          const garbage = drawTiles(rackLength - newRack.length, garbageToTry);
          tempRack = newRack.concat(garbage);
          const tempDisplay = tempRack.join("");
          if (attempts > 200) {
            newRack = generateRack(
              words.filter((w) => !wordsFound.has(w)),
              rackLength,
              maxWords,
              Array.from(badRacks)
            ).split("");
            attempts = 0;
          }
          if (giveUpNow > 220) {
            newRack = generateRack(
              words.filter((w) => !wordsFound.has(w)),
              rackLength,
              maxWords,
              Array.from(badRacks)
            ).split("");
            break;
          }
          currentWords = findAllWords(tempRack, words);
          if (
            currentWords.length < maxWords &&
            currentWords.length >= bestRackCount
          ) {
            bestRack = tempRack;
            console.log("br", bestRack);
            bestRackCount = currentWords.length;
          }
        }
      }
      console.info(`setting rack ${bestRack.sort(alphaSort).join("")}`);
      setGoalWords((x) => {
        const goals = findAllWords(bestRack, words).sort(sortByAlphagram);
        if (bestRack.length < rackLength) {
          console.error("Short rack: ", bestRack, goals.length);
        }
        return goals;
      });

      if (wordsRemainingGame) {
        setDisplayRack(bestRack.sort(alphaSort));
        setWordsFoundRound(new Set());
        setRack(bestRack.sort(alphaSort));
        setInitialized(true);
      }
    }
  }, [
    badRacks,
    initialized,
    rack,
    wordsFound,
    wordsFoundRound,
    wordsRemainingGame,
  ]);

  const handleInput = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      const { key } = e;
      const k = key.toUpperCase();
      if (
        (!rack.length && !wordsToFind?.length) ||
        !wordsRemainingGame ||
        !initialized
      ) {
        return;
      }
      if (rack.includes(k) && attempt.length < rackLength) {
        addToAttempt(k);
      }
      if (key === "Backspace" && attempt.length > 0) {
        removeFromAttempt();
      }
      if (key === " " || key === "Escape") {
        addHint();
      }
    },
    [
      addHint,
      addToAttempt,
      attempt,
      initialized,
      rack,
      removeFromAttempt,
      wordsToFind,
      wordsRemainingGame,
    ]
  );

  useEffect(() => {
    document.addEventListener("keyup", handleInput);
    return () => {
      document.removeEventListener("keyup", handleInput);
    };
  }, [handleInput]);

  const reset = useCallback(() => {
    if (rack.length) {
      if (wordsRemainingGame === wordsFoundRound.size) {
        setWordsFound((x) => new Set([...x, ...goalWords]));
        console.info("END!");
      } else {
        setWordsFound((x) => new Set([...x, ...goalWords]));
        console.info("reload", rack.join(""), timeElapsed);
        setRack([]);
        setErrors(0);
        setHintsUsed(0);
        setAttempt("");
        setWordsFoundRound(new Set());
        setShowSummary(false);

        setTimeElapsed(Date.now() - startTime);
      }
    }
  }, [goalWords, timeElapsed, rack, wordsFound.size]);

  const redo = useCallback(() => {
    if (rack.length) {
      console.info("reload", rack.join(""), timeElapsed);
      setErrors(0);
      setHintsUsed(0);
      setAttempt("");
      setWordsFoundRound(new Set());
      setShowSummary(false);
      setTimeElapsed(Date.now() - startTime);
    }
  }, [goalWords, timeElapsed, rack, wordsFound.size]);

  const summarize = useCallback(() => {
    if (goalWords.length > 0) {
      setShowSummary(true);
    }
  }, [goalWords, errors]);

  useEffect(() => {
    if (allWordsFound || errors >= errorLimit) {
      feedbackTimer.current = setTimeout(summarize, 1000);
    }
  }, [allWordsFound, errors, reset]);

  const test = useMemo(
    () => (
      <div
        style={{
          position: "absolute",
        }}
      >
        <Rack>
          {rack} ({rack.length})
        </Rack>
        <h1>
          {goalWords.length} : {newWordCount}
        </h1>
        <h4>
          {wordsFound.size}/{words.length}
        </h4>
        <h3>{timeElapsed}</h3>
        {goalWords.map((word: string) => (
          <p key={word}>{word}</p>
        ))}
      </div>
    ),
    [rack, goalWords, newWordCount, wordsFound, words, timeElapsed]
  );

  const flip = useMemo(() => {
    return Date.now() % 2 > 0;
  }, [rack]);

  const variation = useMemo(() => {
    return rack.reduce((acc: number, cur) => acc + cur.charCodeAt(0), 0) % 6;
  }, [rack]);

  const score = useMemo(
    () =>
      Math.round((wordsFoundRound.size / goalWords.length) * 100) -
      errors * 10 -
      hintsUsed,
    [errors, hintsUsed, wordsFoundRound]
  );
  const analyticsData = useMemo(
    () => ({
      game: "fourtress",
      score,
      wordsFound: wordsFound.size,
    }),
    [score, wordsFound]
  );

  const lives = useMemo(() => {
    let lifeTemp = [];
    for (let i = errorLimit - errors; i > 0; i--) {
      lifeTemp.push(i);
    }
    return lifeTemp;
  }, [errors]);
  const hitPoints = useMemo(() => {
    let hp = [];
    for (let i = 0; i < goalWords.length - wordsFoundRound.size; i++) {
      hp.push(true);
    }
    for (
      let i = goalWords.length - wordsFoundRound.size;
      i < goalWords.length;
      i++
    ) {
      hp.push(false);
    }

    return (
      <HitPoints>
        <div className="bg" />
        <div className="hp">
          {hp.map((h, i) => (
            <span key={i} className={h ? "life" : "damage"}>
              &nbsp;
            </span>
          ))}
        </div>
      </HitPoints>
    );
  }, [errors, goalWords.length, wordsFoundRound.size]);
  return (
    <Machine
      title={<Title>Fourtress</Title>}
      description="A game for learning four letter words"
    >
      {showSummary ? (
        <GameContainer>
          <Background variation={variation} flip={flip} over={true} />

          <SummaryTitle className={allWordsFound ? "success" : "fail"}>
            {allWordsFound
              ? score === 100
                ? "PERFECT"
                : "ALL WORDS FOUND"
              : "FAIL"}
          </SummaryTitle>
          <SummaryContainer>
            <WordList>
              {goalWords.map((word, i) =>
                wordsFoundRound.has(word) ? (
                  <GoalFound key={i + word}>{word}</GoalFound>
                ) : (
                  <GoalMissed key={i + word}>{word}</GoalMissed>
                )
              )}
            </WordList>
            <Scoreboard>
              <h4>Words Found </h4>
              <p>
                {Math.round((wordsFoundRound.size / goalWords.length) * 100)}%
              </p>
              <h4>Errors </h4>
              <p>-{errors * 10}%</p>
              <h4>Hints Used </h4>

              <p>-{hintsUsed}%</p>
              <h3>Total</h3>
              <p className="total">{score}%</p>
            </Scoreboard>
            <ButtonContainer>
              <Button onClick={reset}>Continue</Button>
              {score < 100 && <Button onClick={redo}>Retry</Button>}
            </ButtonContainer>
            <div className="totals">
              {wordsFound.size + wordsFoundRound.size}/{words.length}
            </div>
          </SummaryContainer>
          <RackSummary>
            {rack.map((t, i) => (
              <span
                key={i + t}
                onClick={() => {
                  addToAttempt(t);
                }}
              >
                {t}
              </span>
            ))}
          </RackSummary>
        </GameContainer>
      ) : (
        <GameContainer>
          <Background variation={variation} flip={flip} />
          <Fortress
            flip={flip}
            totalCount={goalWords.length}
            remainingCount={goalWords.length - wordsFoundRound.size}
          />
          {correct && (
            <>
              <img src={explode} className="explode shrink" />
              <Correct>{correct}</Correct>
            </>
          )}
          <Attempt>
            &nbsp;
            {attempt.split("").map((t, i) => (
              <span
                key={i + t}
                onClick={() => {
                  removeFromAttempt();
                }}
              >
                {t}
              </span>
            ))}
          </Attempt>
          <Rack>
            {rack.map((t, i) => (
              <span
                key={i + t}
                onClick={() => {
                  addToAttempt(t);
                }}
              >
                {t}
              </span>
            ))}
          </Rack>
          <GoalContainer>
            {goalWords.map((word, i) =>
              wordsFoundRound.has(word) ? (
                <GoalFound key={i + word}>{word}</GoalFound>
              ) : (
                <Goal key={i + word} />
              )
            )}
          </GoalContainer>
          {attempt.length === 0 && (
            <button className="add-hint" onClick={addHint}>
              ?
            </button>
          )}
          <LifeContainer>
            {lives.map((t) => (
              <span className="heart" key={t}>
                <i className="fa-solid fa-heart"></i>
              </span>
            ))}
          </LifeContainer>
          {hitPoints}
          <Hint>
            {hint.split("").map((t, i) => (
              <span
                key={`${t}-${i}`}
                onClick={() => {
                  addToAttempt(t);
                  sendEvent("get_hint", analyticsData);
                }}
              >
                {t}
              </span>
            ))}
          </Hint>
          {fail && <Fail>WRONG</Fail>}
        </GameContainer>
      )}
    </Machine>
  );
};
