import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { Machine } from "../_shared/Machine";
import {
  CellType,
  checkSolution,
  getPoolsByLength,
  isFront,
  Solution,
} from "./utils";
import { sendEvent, shuffle } from "../_shared/utils";
import { Tilebelt } from "./components/Tilebelt";
import { Config, configDefaults } from "./config";
import { MachineTitle, Splash, Title } from "./TilebeltElements";
import { getLockDelay, TilePhase } from "./components/Incoming";
import Stars from "./components/Stars";

const THREES = getPoolsByLength(3);
const FOURS = getPoolsByLength(4);
const FIVES = getPoolsByLength(5);

export type CellSet = {
  top?: CellType;
  left?: CellType;
  bottom?: CellType;
  right?: CellType;
};

export type GameState = {
  cells: CellSet;
  runeQueue: string[];
  gameStartTime: number | null;
  gameEndTime: number | null;
  tilePhase: TilePhase | null;
  queueLastModified: number | null;
};

const defaultGameState = {
  cells: {
    top: undefined,
    left: undefined,
    bottom: undefined,
    right: undefined,
  },
  runeQueue: [],
  gameStartTime: null,
  gameEndTime: null,
  tilePhase: null,
  queueLastModified: null,
};

export type MatchResult = {
  rune: string;
  position: keyof CellSet;
  success: boolean;
  word?: string;
};

type HookType = "front" | "back";

export const Hyperspace = () => {
  const [gameState, setGameState] = useState<GameState>(defaultGameState);
  const [matchResult, setMatchResult] = useState<MatchResult | null>(null);
  const matchTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [gameSize, setGameSize] = useState<3 | 4 | 5>(configDefaults.SIZE);
  const [score, setScore] = useState<number>(0);
  const [cellsComplete, setCellsComplete] = useState<number>(0);
  // const [recap, setRecap] = useState<CellType | null>(null);
  const [level, setLevel] = useState<number>(0);
  const [hitPoints, setHitPoints] = useState<number>(configDefaults.MAX_HP);
  const backChoices = useRef<Solution[]>();
  const frontChoices = useRef<Solution[]>();
  const [ready, setReady] = useState(false);
  const inputBounce = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [blockInput, setBlockInput] = useState<boolean>(false);
  const [speed, setSpeed] = useState<number>(configDefaults.INITIAL_SPEED);
  const [searchParams] = useSearchParams();
  const [paused, setPaused] = useState(false);
  const container = useRef<HTMLDivElement>(null);
  const [config, setConfig] = useState<Config>(configDefaults);

  useEffect(() => {
    // Smelly. Make not gross.
    backChoices.current = shuffle(
      gameSize === 3 ? THREES.back : gameSize === 4 ? FOURS.back : FIVES.back
    );
    frontChoices.current = shuffle(
      gameSize === 3 ? THREES.front : gameSize === 4 ? FOURS.front : FIVES.front
    );
  }, [gameSize]);

  const analyticsData = useMemo(
    () => ({
      game: "hyperspace",
      level,
      score,
      speed,
      gameSize,
    }),
    [level, score, speed, gameSize]
  );
  useEffect(() => {
    document.title =
      "StudyCade | Hyperspace Hooks! Learn short words on StudyCade";
    sendEvent("page_view", {
      page_title: "hyperspace",
      page_location: "/#/hyperspace",
    });
    sendEvent("machine_load", { game: "hyperspace" });
  }, []);
  useEffect(() => {
    if (searchParams.get("speed")) {
      const speed = Number(searchParams.get("speed"));
      setConfig((x) => ({
        ...x,
        MAX_SPEED: speed,
        MIN_SPEED: speed,
        INITIAL_SPEED: speed,
      }));
    }
  }, [searchParams]);

  const totalCells = useMemo(() => {
    if (gameSize === 3) {
      return THREES.back.length + THREES.front.length;
    }
    if (gameSize === 4) {
      return FOURS.back.length + FOURS.front.length;
    }
    return FIVES.back.length + FIVES.front.length;
  }, [gameSize]);

  const getNextWord = useCallback((position: HookType): CellType => {
    const choices: Solution[] = [];
    let nextWord;
    if (frontChoices.current && position === ("front" as HookType)) {
      choices.push(...frontChoices.current);
      nextWord = choices.shift();
      frontChoices.current = choices;
    } else if (backChoices.current && position === ("back" as HookType)) {
      choices.push(...backChoices.current);
      nextWord = choices.shift();
      backChoices.current = choices;
    }
    return { solution: nextWord || undefined, collectedRunes: [] };
  }, []);

  const getAttempt = useCallback(
    (position: keyof CellSet) => {
      if (gameState?.cells[position]) {
        return checkSolution(
          gameState.cells[position]!,
          gameState.runeQueue[0]
        );
      } else return null;
    },
    [gameState]
  );

  //Get new cells when they get full, add bonus?
  useEffect(() => {
    const complete = Object.keys(gameState.cells || {}).find((k) => {
      return (
        gameState?.cells[k as keyof CellSet]?.collectedRunes.length ===
        gameState?.cells[k as keyof CellSet]?.solution?.runes.size
      );
    });
    if (gameState.gameStartTime && complete) {
      setHitPoints((x) => config.MAX_HP);
      setLevel((x) => (x + 1 < config.MAX_LEVEL ? x + 1 : config.MAX_LEVEL));
      setCellsComplete((x) => x + 1);
      const newWord = getNextWord(
        isFront(complete as keyof CellSet) ? "front" : "back"
      );
      setGameState((x) => ({
        ...x,
        cells: {
          ...x.cells,
          [complete]: newWord,
        },
        runeQueue: shuffle([
          ...Array.from(newWord?.solution?.runes || []),
          ...x.runeQueue,
        ]),
        queueLastModified: Date.now(),
      }));
      setHitPoints((x) => config.MAX_HP);
    }
  }, [config, gameState, getNextWord]);

  useEffect(() => {
    if (hitPoints === 0) {
      setGameState((x) => ({ ...x, gameEndTime: Date.now() }));
      setReady(false);
      sendEvent("end_game", {
        ...analyticsData,
        fromDeath: true,
      });
    }
  }, [analyticsData, hitPoints]);

  useEffect(() => {
    if (gameState.gameEndTime) {
      setReady(false);
      setGameState((x) => ({ ...x, gameStartTime: null }));
    }
  }, [gameState.gameEndTime]);

  const recycleRune = useCallback(() => {
    setLevel(0);
    setGameState((x) => {
      const reject = x.runeQueue[0];
      return {
        ...x,
        runeQueue: shuffle(x.runeQueue.slice(1)).concat(reject),
        queueLastModified: Date.now(),
      };
    });
  }, [config]);

  const handleMatch = useCallback(
    (rune: string, position: keyof CellSet, tile: CellType) => {
      setScore((x) => x + (level + 1) * 10);
      if (speed > config.MIN_SPEED) {
        setSpeed((x) =>
          x * config.ACCELERATION_RATE > config.MIN_SPEED
            ? x * config.ACCELERATION_RATE
            : config.MIN_SPEED
        );
      }
      if (matchTimer.current) {
        clearTimeout(matchTimer.current);
      }
      setMatchResult({
        rune: rune,
        position: position,
        success: true,
        word: tile.solution!.word!,
      });

      matchTimer.current = setTimeout(() => {
        setMatchResult(null);
        setGameState((x) => ({
          ...x,
          cells: { ...x.cells, [position!]: tile },
          runeQueue: shuffle(x.runeQueue.slice(1)),
          queueLastModified: Date.now(),
        }));
      }, config.PHASE_TIMING.MATCH * speed);
    },
    [config, level, speed]
  );

  const handleFail = useCallback(
    (rune?: string, position?: keyof CellSet) => {
      setHitPoints((x) => x - config.DAMAGE);
      setMatchResult(null);
      if (speed < config.MAX_SPEED) {
        setSpeed((x) => (x * 2 < config.MAX_SPEED ? x * 2 : config.MAX_SPEED));
      }
      setLevel(0);
      if (matchTimer.current) {
        clearTimeout(matchTimer.current);
      }
      if (rune && position) {
        setMatchResult({ rune: rune, position: position, success: false });
      }
      matchTimer.current = setTimeout(
        () => {
          setMatchResult(null);
          setLevel(0);
          recycleRune();
        },
        rune
          ? speed * config.PHASE_TIMING.MISMATCH
          : speed * config.PHASE_TIMING.FAIL
      );
    },
    [config, recycleRune, speed]
  );

  const handleInput = useCallback(
    (skip: boolean, candidate?: keyof CellSet | null) => {
      if (skip) {
        recycleRune();
        setBlockInput(true);
      } else {
        const attempt = candidate ? getAttempt(candidate) : null;
        if (candidate && attempt?.collected) {
          if (!attempt.dupe) {
            setBlockInput(true);
            handleMatch(gameState.runeQueue[0], candidate, attempt?.tile);
          }
        } else {
          setBlockInput(true);
          handleFail(gameState.runeQueue[0], candidate || undefined);
        }
      }
    },
    [getAttempt, handleFail, handleMatch, recycleRune, gameState.runeQueue]
  );

  const handleInput_Keyboard = useCallback(
    (e: KeyboardEvent) => {
      if (e.altKey || e.ctrlKey || e.metaKey || blockInput) {
        return;
      }
      e.preventDefault();
      if (paused) {
        setPaused(false);
        return;
      }
      const { key } = e;
      let candidate: keyof CellSet | null;
      let skip = false;
      switch (key) {
        case "4":
          if (!ready) {
            setGameSize(4);
            setReady(true);
            return;
          }
          candidate = null;
          break;
        case "3":
          if (!ready) {
            setGameSize(3);
            setReady(true);
            return;
          }
          candidate = null;
          break;
        case "5":
          if (!ready) {
            setGameSize(5);
            setReady(true);
            return;
          }
          candidate = null;
          break;
        case "Escape":
          setPaused(true);
          setGameState((x) => {
            const reject = x.runeQueue[0];
            return {
              ...x,
              runeQueue: shuffle(x.runeQueue.slice(1)).concat(reject),
              queueLastModified: Date.now(),
            };
          });
          return;
        case "ArrowUp":
          candidate = "top";
          break;
        case "ArrowLeft":
          candidate = "left";
          break;
        case "ArrowDown":
          candidate = "bottom";
          break;
        case "ArrowRight":
          candidate = "right";
          break;
        case " ":
        case "enter":
          skip = true;
          candidate = null;
          break;
        default:
          candidate = null;
      }
      if (candidate || skip) {
        handleInput(skip, candidate);
      }
    },
    [blockInput, handleInput, ready, paused]
  );

  const clickHandler = useCallback(
    (pos: keyof CellSet) => {
      handleInput(false, pos);
    },
    [handleInput]
  );

  useEffect(() => {
    if (blockInput) {
      inputBounce.current = setTimeout(() => {
        setBlockInput(false);
      }, getLockDelay(speed));
    }
  }, [blockInput, speed]);

  useEffect(() => {
    document.addEventListener("keyup", handleInput_Keyboard);
    return () => {
      document.removeEventListener("keyup", handleInput_Keyboard);
    };
  }, [handleInput_Keyboard]);

  useEffect(() => {
    //initialize game
    if (!gameState.gameStartTime) {
      const top = getNextWord("back");
      const left = getNextWord("back");
      const bottom = getNextWord("front");
      const right = getNextWord("front");
      const newQueue = shuffle([
        ...Array.from(top?.solution?.runes || []),
        ...Array.from(left?.solution?.runes || []),
        ...Array.from(bottom?.solution?.runes || []),
        ...Array.from(right?.solution?.runes || []),
      ]);
      setGameState((x) => ({
        ...x,
        cells: {
          top,
          bottom,
          left,
          right,
        },
        runeQueue: newQueue,
        gameStartTime: Date.now(),
        queueLastModified: Date.now(),
      }));
    }
  }, [gameState.gameStartTime, getNextWord]);

  useEffect(() => {
    if (paused) {
      localStorage.setItem(
        "front",
        JSON.stringify(frontChoices.current, (key, value) => {
          if (value instanceof Set) {
            return [...value.values()];
          }
          return value;
        })
      );
      localStorage.setItem(
        "back",
        JSON.stringify(backChoices.current, (key, value) => {
          if (value instanceof Set) {
            return [...value.values()];
          }
          return value;
        })
      );
      localStorage.setItem("score", score.toString());
      localStorage.setItem("level", level.toString());
      localStorage.setItem(
        "gameState",
        JSON.stringify(gameState, (key, value) => {
          if (value instanceof Set) {
            return [...value.values()];
          }
          return value;
        })
      );
      localStorage.setItem("cellsComplete", cellsComplete.toString());
      localStorage.setItem("gameSize", gameSize.toString());
      localStorage.setItem("speed", gameSize.toString());
      localStorage.setItem("hitPoints", hitPoints.toString());
    }
  }, [paused, gameState, level, score, gameSize, hitPoints]);

  const StartButtons = useMemo(
    () => (
      <div>
        <button
          onClick={() => {
            setGameSize(3);
            setScore(0);
            setLevel(0);
            setCellsComplete(0);
            setMatchResult(null);
            setSpeed(config.INITIAL_SPEED);
            setGameState(defaultGameState);
            setReady(true);
            setHitPoints(config.MAX_HP);
            sendEvent("start_game", { ...analyticsData, gameSize: 3 });
          }}
        >
          3
        </button>
        <button
          onClick={() => {
            setGameSize(4);
            setScore(0);
            setLevel(0);
            setCellsComplete(0);
            setMatchResult(null);
            setSpeed(config.INITIAL_SPEED);
            setGameState(defaultGameState);
            setReady(true);
            setHitPoints(config.MAX_HP);
            sendEvent("start_game", { ...analyticsData, gameSize: 4 });
          }}
        >
          4
        </button>
        <button
          onClick={() => {
            setGameSize(5);
            setScore(0);
            setLevel(0);
            setCellsComplete(0);
            setMatchResult(null);
            setSpeed(config.INITIAL_SPEED);
            setGameState(defaultGameState);
            setReady(true);
            setHitPoints(config.MAX_HP);
            sendEvent("start_game", { ...analyticsData, gameSize: 5 });
          }}
        >
          5
        </button>
      </div>
    ),
    [analyticsData, config]
  );

  if (!gameState) {
    return null;
  }

  if (gameState.gameEndTime) {
    return (
      <Machine
        title={<MachineTitle>Hyperspace Hooks</MachineTitle>}
        description="Use the arrow keys or tap to find the valid extensions."
      >
        <>
          <Splash style={{ color: "white" }} tabIndex={1}>
            <Stars speed={30} />
            <Stars speed={100} />
            <h1>Game Over</h1>
            <br />
            <h2>{score}</h2>
            <h3>
              {" "}
              {cellsComplete}/{totalCells}
            </h3>
            {StartButtons}
          </Splash>
        </>
      </Machine>
    );
  }

  if (!ready) {
    return (
      <Machine
        title={<MachineTitle className="">Hyperspace Hooks</MachineTitle>}
        description="Use the arrow keys or tap to find the valid extensions."
      >
        <>
          <Stars speed={400} />
          <Stars speed={1200} />
          <Splash style={{ color: "white" }} tabIndex={1}>
            <Title>Hyperspace Hooks</Title>
            {StartButtons}
            {!!localStorage.getItem("front") && (
              <button
                className="reload"
                onClick={() => {
                  setGameSize(
                    localStorage.getItem("gameSize") === "5"
                      ? 5
                      : localStorage.getItem("gameSize") === "4"
                      ? 4
                      : 3
                  );
                  setScore(parseInt(localStorage.getItem("score") || "0", 10));
                  setLevel(parseInt(localStorage.getItem("level") || "0", 10));
                  setCellsComplete(
                    parseInt(localStorage.getItem("cellsComplete") || "0", 10)
                  );
                  frontChoices.current = JSON.parse(
                    localStorage.getItem("front") || "{}",
                    (key, value) => {
                      if (key === "runes") {
                        return new Set(value);
                      }
                      return value;
                    }
                  );
                  backChoices.current = JSON.parse(
                    localStorage.getItem("back") || "{}",
                    (key, value) => {
                      if (key === "runes") {
                        return new Set(value);
                      }
                      return value;
                    }
                  );
                  setMatchResult(null);
                  setSpeed(
                    parseInt(
                      localStorage.getItem("speed") ||
                        config.INITIAL_SPEED.toString(),
                      10
                    )
                  );
                  try {
                    setGameState(
                      JSON.parse(
                        localStorage.getItem("gameState") || "{}",
                        (key, value) => {
                          if (key === "runes") {
                            return new Set(value);
                          }
                          return value;
                        }
                      )
                    );
                  } catch (e) {
                    setGameState(defaultGameState);
                  }
                  setReady(true);
                  setHitPoints(
                    parseInt(
                      localStorage.getItem("hitPoints") ||
                        config.MAX_HP.toString(),
                      10
                    )
                  );
                  sendEvent("load_game", analyticsData);
                }}
              >
                Load saved
              </button>
            )}
          </Splash>
        </>
      </Machine>
    );
  }

  if (paused) {
    return (
      <Machine
        title={<MachineTitle>Hyperspace Hooks</MachineTitle>}
        description="Use the arrow keys or tap to find the valid extensions."
      >
        <>
          <Splash
            style={{ color: "white" }}
            tabIndex={1}
            onClick={() => {
              setPaused(false);
            }}
          >
            <Title>Hyperspace Hooks</Title>
            <button
              className="continue"
              onClick={() => {
                setPaused(false);
                sendEvent("unpause", analyticsData);
              }}
            >
              Continue
            </button>
          </Splash>
        </>
      </Machine>
    );
  }
  return (
    <Machine
      title={<MachineTitle>Hyperspace Hooks</MachineTitle>}
      description="Use the arrow keys or tap to find the valid extensions."
    >
      <div ref={container}>
        <Tilebelt
          analyticsData={analyticsData}
          cellsCompleted={cellsComplete}
          config={config}
          level={level}
          gameState={gameState}
          rune={gameState.runeQueue[0]}
          key={gameState.gameStartTime}
          gameSize={gameSize}
          handleFail={handleFail}
          hp={hitPoints}
          matchResult={matchResult}
          paused={paused}
          queueLastModified={gameState.queueLastModified}
          handleClick={clickHandler}
          handlePauseClick={() => {
            setPaused(true);
          }}
          score={score}
          totalCells={totalCells || 0}
          speed={speed}
        />
      </div>
    </Machine>
  );
};
