import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CellSet, GameState, MatchResult } from "../hyperspace";
import { Cell, CellBlock } from "./Cell";
import { CellType, isFront } from "../utils";
import { Incoming, TilePhase } from "./Incoming";
import { Config, configDefaults as config } from "../config";
import * as TB from "../TilebeltElements";
import { GameContainer, PauseButton } from "../TilebeltElements";
import Stars from "./Stars";
import Collection from "./Collection";
// import { Recap } from "./Recap";

type TilebeltProps = {
  analyticsData: any;
  cellsCompleted: number;
  config: Config;
  gameSize: 3 | 4 | 5;
  gameState: GameState;
  handleClick: (pos: keyof CellSet) => void;
  handleFail: () => void;
  handlePauseClick: () => void;
  hp: number;
  level: number;
  matchResult: MatchResult | null;
  paused: boolean;
  rune?: string;
  score: number;
  speed: number;
  totalCells: number;
  queueLastModified: number | null;
};

export type PositionType = {
  x: number;
  y: number;
  r: number;
};

export const Tilebelt = ({
  analyticsData,
  cellsCompleted,
  gameSize,
  gameState,
  handleClick,
  handleFail,
  hp,
  level,
  matchResult,
  paused,
  handlePauseClick,
  queueLastModified,
  rune,
  speed,
  score,
  totalCells,
}: TilebeltProps) => {
  const { top, left, right, bottom } = gameState.cells;
  const [tilePhase, setTilePhase] = useState<TilePhase | null>(null);
  const [active, setActive] = useState<boolean>(true);

  useEffect(() => {
    if (tilePhase === TilePhase.Destroyed) {
      setActive(false);
      handleFail();
    }
  }, [tilePhase, handleFail]);

  useEffect(() => {
    if (tilePhase === TilePhase.Complete) {
      setActive(false);
    }
  }, [tilePhase]);

  const tileKey = useMemo(
    () =>
      rune && queueLastModified
        ? `${queueLastModified.toString()}-${rune}`
        : null,
    [queueLastModified, rune]
  );

  useEffect(() => {
    setActive(!!tileKey);
  }, [tileKey]);

  const renderTile = useCallback(
    (cell: CellType, pos: keyof CellSet) => {
      const remaining =
        (cell?.solution?.runes?.size || 0) - cell.collectedRunes?.length;
      const alreadyCollected = !!rune && cell.collectedRunes.includes(rune);
      const renderTarget = () => {
        if (matchResult?.position === pos) {
          return (
            <CellBlock
              count={remaining}
              rune={matchResult.rune}
              error={!matchResult.success}
              matched={matchResult.success}
              key={`${matchResult.position}${matchResult.word}${matchResult.rune}${matchResult.success}`}
              speed={speed}
            />
          );
        }
        return (
          <CellBlock
            count={remaining}
            alreadyCollected={alreadyCollected}
            rune={alreadyCollected ? rune : undefined}
            matched={matchResult?.position === pos && matchResult.success}
            previewRune={rune}
            key={`${remaining}${rune}${matchResult?.position}${matchResult?.word}${matchResult?.rune}${matchResult?.success}`}
            speed={speed}
          />
        );
      };
      return (
        <TB.CellWrapper className={`wrapper-${pos}`}>
          <div
            className={`cell ${pos}${
              alreadyCollected ? " already-collected" : ""
            }`}
          >
            {isFront(pos) && renderTarget()}
            <Cell
              gameSize={gameSize}
              cell={cell}
              matchResult={
                matchResult?.position === pos ? matchResult : undefined
              }
              key={`${cell.solution?.word}-${pos}-${matchResult?.word || "@"}`}
              alreadyCollected={alreadyCollected}
            />
            {!isFront(pos) && renderTarget()}
          </div>
        </TB.CellWrapper>
      );
    },
    [gameSize, matchResult, rune, speed]
  );

  if (!top || !left || !right || !bottom) {
    return null;
  }

  return (
    <GameContainer className={`HUD-${tilePhase}`}>
      <Stars speed={100} />
      <Stars speed={300} />
      <Stars speed={900} />
      <Stars speed={2700} />
      <Collection cell={top} pos={"top"} />
      <Collection cell={left} pos={"left"} />
      <TB.Grid>
        <TB.Score>
          <p className="score">{score}</p>
          <p className="completions">
            {cellsCompleted}/{totalCells}
          </p>
        </TB.Score>
        <TB.Top
          className={"cell-container"}
          onClick={() => {
            if (tilePhase === TilePhase.Normal) {
              handleClick("top");
            }
          }}
        >
          {renderTile(top, "top")}
        </TB.Top>
        <TB.Life className={`${hp < config.HP_WARNING} ? 'low': ''`}>
          <div>
            <span className="heart">
              <i className="fa-solid fa-heart"></i>
            </span>{" "}
            {hp}
          </div>
          <p className="level">{level > 0 ? `x${level + 1}` : ""}</p>
        </TB.Life>
        <TB.Left
          className={"cell-container"}
          onClick={() => {
            if (tilePhase === TilePhase.Normal) {
              handleClick("left");
            }
          }}
        >
          {renderTile(left, "left")}
        </TB.Left>
        {active && rune ? (
          <Incoming
            config={config}
            rune={rune}
            handleStateChange={setTilePhase}
            speed={speed}
            matchResult={matchResult || undefined}
            key={tileKey}
          />
        ) : (
          <TB.Empty></TB.Empty>
        )}
        <TB.Right
          className={"cell-container"}
          onClick={() => {
            if (tilePhase === TilePhase.Normal) {
              handleClick("right");
            }
          }}
        >
          {renderTile(right, "right")}
        </TB.Right>
        <div></div>
        <TB.Bottom
          className={"cell-container"}
          onClick={() => {
            if (tilePhase === TilePhase.Normal) {
              handleClick("bottom");
            }
          }}
        >
          {renderTile(bottom, "bottom")}
        </TB.Bottom>
        <div>
          <PauseButton onClick={handlePauseClick}>
            <i className="fa-solid fa-pause" />
          </PauseButton>
        </div>
      </TB.Grid>
      <Collection cell={right} pos={"right"} />
      <Collection cell={bottom} pos={"bottom"} />
    </GameContainer>
  );
};
