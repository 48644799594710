import React, { useMemo } from "react";
import styled, { keyframes } from "styled-components";
import { signedRandom } from "../../_shared/utils";

type StarsProps = {
  speed: number;
  x?: number;
  y?: number;
};

const Starfield = styled.div`
  width: 100vw;
  height: 100vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: top left;
  pointer-events: none;
`;

const fly = (x: number, y: number, z: number) => keyframes`
  0% {
    transform: translate3d(0vw, 0vh, 0);
    visibility: visible;
    opacity: 0;
  }
  100% {
    transform: translate3d(${x * 2}vw, ${y * 2}vh, ${z}vh);
    visibility: visible;
    opacity: 1;
  }
`;

type StarProps = {
  z: number;
  speed: number;
  x: number;
  y: number;
  delay: number;
};

const Star = styled.div<StarProps>`
  width: 0.3vh;
  height: 0.3vh;
  background: white;
  animation: ${({ x, y, z }) => fly(x, y, z)} ${({ speed }) => speed}ms linear
    infinite;
  animation-delay: ${({ delay }) => delay}ms;
  perspective: ${({ speed }) => 20 / speed}vh;
  visibility: hidden;
`;

const Stars = ({ speed }: StarsProps) => {
  const stars = useMemo(() => {
    const bucket = [];
    for (let i = 0; i < 20; i++) {
      bucket.push(
        <Star
          key={i}
          z={Math.round(Math.random() * 200)}
          speed={speed * 10}
          x={signedRandom(100)}
          y={signedRandom(50)}
          delay={i * 300}
        />
      );
    }
    return bucket;
  }, [speed]);

  return <Starfield className="stars">{stars}</Starfield>;
};

export default Stars;
