import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import * as SC from "../sharedStyles";
import { Logo } from "./logo";
import { sc, SCTheme } from "../sharedStyles";

type NavProps = {
  children?: React.ReactNode;
  menu?: React.ReactNode;
};

const Header = styled.header`
  height: 9vh;
  text-align: center;
  @media (min-aspect-ratio: 5/7) {
    text-align: left;
  }
`;

const NavLogo = styled(Logo)`
  font-size: 3.5vh;
  margin: 3vh 0;
  ${({ theme }) => theme.navLogoOverrides}
  @media (min-aspect-ratio: 5/7) {
    margin: 3.8vh 6vh;
    justify-content: flex-start;
    font-size: 2vh;
  }
  @media (min-aspect-ratio: 5/6) {
    margin: 3vh 6vh;
    justify-content: flex-start;
    font-size: 3vh;
  }
`;

const Menu = styled.aside`
  display: flex;
  position: absolute;
  left: 0;
  bottom: 6vh;
  width: 100%;
  text-align: center;
  gap: 2vh;
  justify-content: center;
  font-size: 2vh;
  color: ${sc("body_bg")};
  button {
    font-size: 1.8vh;
  }
  transform: scale(0.8);
  @media (min-aspect-ratio: 5/7) {
    top: 2.5vh;
    height: fit-content;
    gap: 1vh;
    justify-content: flex-end;
    padding-right: 4vh;
    width: auto;
    position: absolute;
    left: auto;
    right: 0;
    transform: scale(0.9);
    button {
      font-size: 1.7vh;
      padding: 1.5vh;
    }
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  @media (min-width: ${SC.SCREEN_WIDTH.md.min}px) {
    flex-direction: row;
  }
`;

const Copyright = styled.footer`
  position: absolute;
  font-size: 1.4vh;
  opacity: 0.8;
  bottom: 3vh;
  right: 7vh;
  @media (min-aspect-ratio: 4/5) {
    bottom: 2vh;
  }
`;

const NavGlobalStyles = createGlobalStyle<{ theme: SCTheme }>`
  ${(props) => props.theme.navOverrides}
  footer a {
    color: white;
    margin: 1vw;
    font-weight: 800;
    font-family: Montserrat;
    text-decoration: none;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.44);
  }
`;

export const Nav = ({ children, menu }: NavProps) => {
  return (
    <>
      <NavGlobalStyles />
      <Header>
        <SC.LinkPlain to="/">
          <NavLogo />
        </SC.LinkPlain>
      </Header>
      <Menu>
        <SC.ButtonSecondary>
          <SC.LinkPlain to="/letsroll">Let's Roll</SC.LinkPlain>
        </SC.ButtonSecondary>
        <SC.Button>
          <SC.LinkPlain to="/triad">Tower of Threes</SC.LinkPlain>
        </SC.Button>
        <SC.ButtonSecondary>
          <SC.LinkPlain to="/hyperspace">Hyperspace Hooks</SC.LinkPlain>
        </SC.ButtonSecondary>
      </Menu>
      <Main>{children}</Main>
      <Copyright className="copyright">
        © 2022-{new Date().getFullYear()} Lola McKissen
        <a
          href="https://www.patreon.com/Studycade"
          target="_blank"
          rel="noreferrer"
        >
          Support StudyCade!
        </a>
      </Copyright>
    </>
  );
};
